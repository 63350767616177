.infographics-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3%;
  text-align: center;
  border: rgba(255, 255, 255, 0.2) solid;
  border-radius: 10px;

  box-shadow: 0px 5px 20px rgb(0 0 0 / 12%);

  flex-direction: row;
  border-radius: 20px;
  flex-wrap: wrap;
  margin: 5%;
  @media screen and (max-width: 700px) {
    display: block;
  }
}

.infographics-item-holder {
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  flex-direction: row;
  align-items: center;

  flex-wrap: wrap;

  @media screen and (max-width: 700px) {
    text-align: left;
  }
}

.infographics-number {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  flex: 1;
  color: color-bg(white);
  margin-bottom: 0px;
}

.infographics-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  flex: 1;
  color: color-bg(white);
  margin-bottom: 0px;
  margin-left: 10px;
}

.infographics-holder-home {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  text-align: center;

  border: rgba(255, 255, 255, 0.3) solid;
  border-radius: 10px;

  box-shadow: 0px 5px 20px rgb(0 0 0 / 12%);

  flex-direction: row;
  border-radius: 20px;
  flex-wrap: wrap;

  @media screen and (max-width: 700px) {
    display: block;
    margin-top: 20px !important;
  }
}

.progress {
  display: inline-block;
  width: 5px;
  height: 800px;
  margin: 35px;
  border-radius: 20px;
  background: #f9f9f9;

  @media screen and (max-width: 700px) {
  }
}

.progress-bar-holder-center {
  flex: 1;
  width: 30%;
}

.progress-bar-holder-left {
  flex: 1;
  width: 30%;
}

.progress-bar-holder-right {
  flex: 1;
  width: 30%;
}

.progress-bar-holder {
  display: flex;
  flex-direction: row;
  padding: 5%;
  align-items: center;
  justify-content: center;
}

.bar-width {
  border-radius: 20px;
  width: 100%;
  height: 0%;
  transition: width;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.36, 0.55, 0.63, 0.48);
}

.mobile {
  display: none;
}

.shadow {
  /* 25 50 */
}

.overlap {
  background-color: color-bg(secondary);
}

@media screen and (max-width: 500px) {
  .github {
    margin: 20px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: inherit;
  }
}

.about-detail-number {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 0px;
  color: color-bg(primary);
}

.about-detail-holder-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.about-detail-number-holder-left {
  display: flex;
  background-color: color-bg(white);
  border-radius: 10px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 20px rgb(0 0 0 / 12%);
  text-align: center;
  width: 40px;
  height: 40px;
  margin-bottom: 3%;
}

.about-detail-title-left {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  margin-bottom: 3%;
  text-align: right;

  color: color-bg(body);
}
.about-detail-body-left {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 50%;
  text-align: right;
  color: color-bg(white);

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.about-detail-holder-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.about-detail-number-holder-right {
  display: flex;
  background-color: color-bg(white);
  box-shadow: 0px 5px 20px rgb(0 0 0 / 12%);
  border-radius: 10px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 40px;
  height: 40px;
  margin-bottom: 3%;
}

.about-detail-title-right {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  margin-bottom: 3%;
  text-align: left;

  color: color-bg(body);
}
.about-detail-body-right {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 50%;
  text-align: left;
  color: color-bg(white);
  font-family: unquote("Space Grotesk");

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}
