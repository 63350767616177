.logos-holder {
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  background-color: color(secondary-light);
  flex-direction: row;
  border-radius: $button--radius;
  flex-wrap: wrap;
  margin-bottom: 10%;
  height: 110px;

  @media screen and (max-width: 700px) {
    margin: 0;
    margin-bottom: 20px;
    height: auto;
    padding: 20px;
  }
}

.technologies-holder {
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  background-color: color(secondary-light);
  flex-direction: row;
  border-radius: 20px;
  flex-wrap: wrap;
  margin-bottom: 10%;
}

.logo-holder {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
  margin: auto;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    min-width: 30%;
    min-width: 100%;
    margin: 0;
    margin-bottom: 20px;
  }
}

.filter-blue {
  filter: invert(36%) sepia(61%) saturate(1405%) hue-rotate(223deg)
    brightness(98%) contrast(105%);
}

.filter-gray {
  filter: invert(74%) sepia(1%) saturate(0%) hue-rotate(90deg) brightness(96%)
    contrast(92%);
}

.filter-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
    brightness(102%) contrast(102%);
}
