.header-holder {
  display: flex;
  justify-content: flex-start;
  margin: auto;
  text-align: left;

  flex-direction: row;
}

.path-holder {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  text-align: left;
  cursor: pointer;
  flex-direction: row;
}

.split {
  background-color: color-bg(white);
  width: 5px;
  height: 5px;
  border-radius: 5px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
  margin-right: 5px;
}

.location {
  display: flex;
  flex-direction: row;
}

.path-name {
  margin-top: auto;
  margin-bottom: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding: 5px;
}

.bold {
  font-weight: 600;
}

.path-title {
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 65px;
  /* identical to box height, or 144% */

  text-align: center;

  color: color-bg(white);
}

.price-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: color-bg(white);
  width: 80%;
  background: linear-gradient(45.09deg, #8594fa 0.08%, #5a6650 60.76%);
  box-shadow: 0px 5px 20px rgb(0 0 0 / 12%);
  padding: 5px;
  border-radius: 5px;
  /* identical to box height, or 144% */
  text-align: center;
  transition-duration: 1s;

  span {
    font-size: 18px;
  }
}

.service-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px;
  color: color-bg(white);
}

.header-circle-orbit {
  margin-top: -100px;
  margin-right: -200px;
  position: absolute;
  left: 5%;
  z-index: 1;
  transform: scaleX(-1);
}

.header-circle {
  position: absolute;
  left: -10%;
  z-index: -1;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background-color: color-bg(body-3);
  top: 2%;
}

.header-logo {
  margin: 4%;
}

.path-description {
  margin-bottom: 0px !important;
  text-align: center;
  color: color-bg(white);
}

.path-split {
  width: 40px;
  height: 3px;
  background-color: color-bg(primary);
  border-radius: 3px;
  align-content: center;
  margin-bottom: 12px;
}

.details-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: color-bg(white);
}

.contact-holder {
  margin-bottom: 50px;
}

.header-action-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
}
