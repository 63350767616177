.button {
  display: inline-flex;
  @include font-size(button);
  @include font-weight(button);

  padding: (
      (
          $button-form--height - get-line-height(button) -
            ($button-form--border-width * 2)
        ) * 0.5
    )
    ($button--padding-h - $button-form--border-width);
  height: $button-form--height;
  text-decoration: none !important;
  text-transform: $button--transform;
  color: color-bg(white);
  background-color: color-bg(primary);
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: inherit;
  white-space: nowrap;
  transition: background 0.15s ease;

  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: var(--hff);
  text-transform: uppercase;

  &:active {
    outline: 0;
  }

  &:hover {
    background-color: color-bg(button-light-hover);
    border-color: color-border(button-light-hover);
  }

  &.is-loading {
    position: relative;
    color: transparent !important;
    pointer-events: none;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: get-line-height(button);
      height: get-line-height(button);
      margin-left: -(get-line-height(button) * 0.5);
      margin-top: -(get-line-height(button) * 0.5);
      top: 50%;
      left: 50%;
      border: $button-loading--thickness solid color(button-light);
      border-radius: 50%;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      z-index: 1;
      animation: button-loading 0.6s infinite linear;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    color: color(button-disabled);
    background-color: color-bg(button-disabled) !important;
    border-color: color-border(button-disabled);

    &.is-loading {
      &::after {
        border-color: color(button-disabled);
      }
    }
  }
}

.button-sm {
  padding: (
      (
          $button-form-sm--height - get-line-height(button) -
            ($button-form--border-width * 2)
        ) * 0.5
    )
    ($button-sm--padding-h - $button-form--border-width);
  height: $button-form-sm--height;
}

.button-dark {
  color: color(button-dark);
  background-color: color-bg(button-dark);
  border-color: color-border(button-dark);

  &:hover {
    background-color: color-bg(button-dark-hover);
    border-color: color-border(button-dark-hover);
  }

  &.is-loading {
    &::after {
      border-color: color(button-dark);
    }
  }
}

.button-get-in-touch {
  color: color(button-primary);
  background-color: color-bg(primary);
  border-color: color-border(button-primary);
  border-radius: 20px 20px 20px 0px !important;
  margin-top: 5px;
  margin-right: 35px;

  &:hover {
    background-color: color-bg(button-primary-hover);
    border-color: color-border(button-primary-hover);
  }

  &.is-loading {
    &::after {
      border-color: color(button-primary);
    }
  }
}

.button-send {
  color: color(button-primary);
  background-color: color-bg(primary);
  border-color: color-border(button-primary);
  border-radius: $button--radius !important;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 10px;

  &:hover {
    background-color: color-bg(button-primary-hover);
    border-color: color-border(button-primary-hover);
  }

  &.is-loading {
    &::after {
      border-color: color(button-primary);
    }
  }
}

.get-in-touch {
  @include font-size(xxs);
}

.button-primary {
  color: color(button-primary);
  background-color: get-color(cloud_primary, 1);
  border-color: color-border(button-primary);
  border-radius: 5px;

  &:hover {
    background-color: get-color(cloud_primary, 3);
    border-color: color-border(button-primary-hover);
  }

  &.is-loading {
    &::after {
      border-color: color(button-primary);
    }
  }
}

.button-secondary {
  color: get-color(cloud_primary, 1);
  background-color: get-color(cloud_base, 2);
  border-color: get-color(cloud_primary, 1);

  &:hover {
    background-color: get-color(cloud_primary, 2);
    border-color: get-color(cloud_primary, 2);
    color: get-color(cloud_base, 2);
  }

  &.is-loading {
    &::after {
      border-color: color(button-secondary);
    }
  }
}

.button-social {
  flex: 1;
  max-height: 56px;
  min-height: 56px;
  max-width: 56px;
  min-width: 56px;
  width: 56px;
  background-color: color-bg(white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.button-social:hover {
  background-color: color-bg(gray);
}

.button-social-disabled {
  flex: 1;
  max-height: 56px;
  min-height: 56px;
  max-width: 56px;
  min-width: 56px;
  width: 56px;
  background-color: color-bg(grey);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 15px;
  margin-right: 15px;
}

.button-block {
  display: flex;
  width: 100%;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: -($button-group-padding * 0.5);
  margin-left: -($button-group-padding * 0.5);
  margin-top: -($button-group-padding * 0.5);

  &:last-of-type {
    margin-bottom: -($button-group-padding * 0.5);
  }

  &:not(:last-of-type) {
    margin-bottom: ($button-group-padding * 0.5);
  }

  > [class*="button"] {
    margin: $button-group-padding * 0.5;
  }
}

@include media("<=medium") {
  .button-wide-mobile {
    width: 100%;
    max-width: $button--max-size-mobile;
  }
}

@keyframes button-loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.button-white {
  display: inline-flex;
  @include font-size(button);
  @include font-weight(button);

  padding: (
      (
          $button-form--height - get-line-height(button) -
            ($button-form--border-width * 2)
        ) * 0.5
    )
    ($button--padding-h - $button-form--border-width);
  height: $button-form--height;
  text-decoration: none !important;
  text-transform: $button--transform;
  color: color-bg(black);
  background-color: color-bg(white);
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: inherit;
  white-space: nowrap;
  transition: background 0.15s ease;

  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: var(--hff);
  text-transform: uppercase;

  &:active {
    outline: 0;
  }

  &:hover {
    background-color: color-bg(button-light-hover);
    border-color: color-border(button-light-hover);
  }

  &.is-loading {
    position: relative;
    color: transparent !important;
    pointer-events: none;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: get-line-height(button);
      height: get-line-height(button);
      margin-left: -(get-line-height(button) * 0.5);
      margin-top: -(get-line-height(button) * 0.5);
      top: 50%;
      left: 50%;
      border: $button-loading--thickness solid color(button-light);
      border-radius: 50%;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      z-index: 1;
      animation: button-loading 0.6s infinite linear;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    color: color(button-disabled);
    background-color: color-bg(button-disabled) !important;
    border-color: color-border(button-disabled);

    &.is-loading {
      &::after {
        border-color: color(button-disabled);
      }
    }
  }
}

.button-secondary {
  display: inline-flex;
  @include font-size(button);
  @include font-weight(button);

  padding: (
      (
          $button-form--height - get-line-height(button) -
            ($button-form--border-width * 2)
        ) * 0.5
    )
    ($button--padding-h - $button-form--border-width);
  height: $button-form--height;
  text-decoration: none !important;
  text-transform: $button--transform;
  color: color-bg(white);
  background-color: color-bg(secondary);
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: inherit;
  white-space: nowrap;
  transition: background 0.15s ease;

  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: var(--hff);
  text-transform: uppercase;

  &:active {
    outline: 0;
  }

  &:hover {
    background-color: color-bg(secondary-dark);
    border-color: color-border(button-light-hover);
  }

  &.is-loading {
    position: relative;
    color: transparent !important;
    pointer-events: none;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: get-line-height(button);
      height: get-line-height(button);
      margin-left: -(get-line-height(button) * 0.5);
      margin-top: -(get-line-height(button) * 0.5);
      top: 50%;
      left: 50%;
      border: $button-loading--thickness solid color(button-light);
      border-radius: 50%;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      z-index: 1;
      animation: button-loading 0.6s infinite linear;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    color: color(button-disabled);
    background-color: color-bg(button-disabled) !important;
    border-color: color-border(button-disabled);

    &.is-loading {
      &::after {
        border-color: color(button-disabled);
      }
    }
  }
}
