.subscribe-background {
  width: 100%;
  left: 0px;
  padding: 20px;

  border: rgba(255, 255, 255, 0.2) solid;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.input-eye-holder {
  position: relative;
  display: flex;
  float: right;
  margin-left: -40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.password-holder {
  display: flex;
  flex-direction: row;
}

.loader {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: block;
  margin: 22.5px auto;
  position: relative;
  color: #000;
  left: -100px;
  box-sizing: border-box;
  animation: shadowRolling 2s linear infinite;
}

@keyframes shadowRolling {
  0% {
    box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0);
  }
  36% {
    box-shadow: 120px 0 white, 110px 0 white, 100px 0 white,
      0px 0 rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
  }
  62% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white,
      110px 0 white;
  }
  75% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      130px 0 white, 120px 0 white;
  }
  87% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      200px 0 rgba(255, 255, 255, 0), 130px 0 white;
  }
  100% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
  }
}

.subscribe-icon-holder {
  position: relative;
  width: 100%;
  margin-top: -40px;
  margin-left: auto;
  right: 0px;
}

.subscribe-icon {
  margin-right: -40px;
  margin-left: auto;
  width: 74px;
  height: 74px;
}

.subscribe-title {
  width: 80%;
  font-style: normal;
  font-size: 26px;
  line-height: 35px;
  display: flex;
  align-items: center;
  text-align: center;

  color: color-bg(white);

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.subscribe-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #30295b;
}

.subscribe-body {
  justify-content: center;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 20px;
}

.input-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.send-button {
  width: 150px;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.email-input {
  width: 50%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #efeeee;
  padding: 0px 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #30295b;
  background-color: #000;
  margin-right: 3%;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-bottom: 10px;
  }
}
