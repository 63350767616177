.project-section-background-left {
  width: 100%;
  left: 0;
  margin-top: 20px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  background-color: #fcfcfc;
}

.project-section-background-right {
  width: 100%;
  left: 0px;
  margin-top: 20px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}

.community-section-background-left {
  width: 100%;
  left: 0;
  margin-top: 20px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  background-color: #fcfcfc;
  z-index: 10;
}

.community-section-background-right {
  width: 100%;
  left: 0px;
  margin-top: 20px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  background-color: #fcfcfc;
}

.project-section-title {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  display: flex;
  align-items: center;

  color: #080421;
}

.community-section-title {
  font-style: normal;
  font-size: 28px;
  line-height: 35px;
  display: flex;
  align-items: center;

  color: #080421;
}

.community-section-articles-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
}

.community-section-body {
  justify-content: center;
  display: flex;
  text-align: left;
  flex-direction: column;
  padding: 40px;
  // flex: 1;
  min-width: 50%;

  @media screen and (max-width: 700px) {
    margin-top: 20px;
  }
}

.project-section-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: justify;

  color: #30295b;
}

.project-section-is-device {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.project-section-is-device-label {
  margin-bottom: 0px;
  margin-right: 10px;
}

.project-section-image-left {
  border-radius: 5px 20px 5px 0px;
  width: 450px;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f92a82;
}

.project-section-image-right {
  border-radius: 20px 5px 0px 5px;
  width: 450px;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f92a82;
}

.project-section-image-holder {
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 700px) {
    padding: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.community-section-text-holder {
  flex: 1;
  padding: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  @media screen and (max-width: 700px) {
    padding: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.community-section-article-holder {
  padding: 20px;
}

.community-section-article-title {
  font-weight: 800;
}

.project-section-body {
  justify-content: flex-start;
  display: flex;
  text-align: left;
  flex-direction: column;
  padding: 40px;
  // flex: 1;
  min-width: 50%;

  @media screen and (max-width: 700px) {
    margin-top: 20px;
  }
}

.project-section-holder {
  display: flex;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.device-holder {
  position: absolute;
  width: 450px;
  height: 300px;
  display: flex;
  justify-content: center;
}
