.is-boxed {
  background: color-bg(body-outer);

  .body-wrap {
    max-width: $container--width-boxed;
    margin: 0 auto;
    box-shadow: color-bg(body-shadow);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: color-bg(primary);
      z-index: -10;
    }
  }
}

.body-wrap {
  position: relative;
  overflow: hidden;
  // Sticky footer
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-content {
  flex: 1 0 auto;
  margin-top: 100px;
  border-radius: $button--radius;
  padding-left: 8%;
  padding-right: 8%;

  @media screen and (max-width: 700px) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.hero-secondary-title {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 42px;
  display: inline;
  align-items: center;
  text-align: center;
  margin-bottom: 1%;

  color: color-bg(body);

  @media screen and (max-width: 700px) {
    width: 100%;
    padding: 0;
    text-align: left;
  }
}

.hero-secondary-title-blue {
  line-height: 42px;
  color: #000;
  margin-bottom: 0 !important;
}

.hero-secondary-title-italic {
  font-style: italic;
}

.hero-secondary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  padding-bottom: 2%;
  border-radius: 10px;
  overflow: hidden;
}

.hero-secondary-body {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-top: 10px;
  text-align: center;
  /* or 133% */
  color: color-bg(white);

  display: flex;
  align-items: center;
}

.secondary-dark {
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 20px;
  background: #f5f7fb;
  padding: 3%;
  padding-left: 5%;
  padding-right: 5%;
}
