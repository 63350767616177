.detail-card {
  transition: box-shadow 0.6s;
  background: #ffffff;
  border-radius: 20px;
  flex: 1;
  text-align: center;
  padding: 3%;
  padding-top: 5%;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 10px;
  margin-left: 30px;
  margin-right: 30px;
  justify-content: center;
  cursor: pointer;
  min-width: 27%;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    flex: none;
  }
}

.detail-card:hover {
  box-shadow: 10px 10px 20px rgba(8, 4, 33, 0.1);
}

.details-card-holder {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.detail-title {
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;

  color: #30295b;
}

.detail-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: justify;

  color: #090909;
}

.detail-image {
  padding: 10px;
  height: 64px;
  width: 64px;
}

.detail-card-list-item {
  padding-left: 5px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #090909;
}

.detail-card-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #090909;
}

.detail-card-list-item-detailed {
  padding-left: 5px;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #30295b;
}

.detail-card-list-item-detail-info {
  font-style: normal;
  font-weight: 400;
  padding-left: 5px;

  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #090909;
}
