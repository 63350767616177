.typography-table {
  margin-left: 50px;
}

.typography-style-text {
  margin-bottom: 0px !important;
  line-height: 16px;
  font-size: 13px;
}

.typography-name-text {
  margin-bottom: 0px !important;
  line-height: 20px;
}

.typography-display {
  font-weight: bold;
  font-size: 60px;
  line-height: 70px;
  color: color-bg(white);
}

.bold {
  font-weight: 900 !important;
}

.blue {
  color: color-bg(primary) !important;
}

.typography-h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 45px;
  color: color-bg(white);
}

.typography-h2 {
  font-weight: bold;

  font-size: 30px;

  line-height: 35px;

  color: color-bg(white);
}

.typography-h3 {
  font-weight: bold;

  font-size: 22px;

  line-height: 28px;

  color: color-bg(white);
}

.typography-h4 {
  font-weight: bold;

  font-size: 18px;

  line-height: 22px;

  color: color-bg(white);
}

.typography-h5 {
  font-weight: normal;

  font-size: 13px;

  line-height: 16px;

  color: color-bg(white);

  text-transform: uppercase;
}

.typography-h6 {
  font-weight: normal;

  font-size: 13px;

  line-height: 16px;

  color: color-bg(white);
}

.typography-body {
  font-weight: normal;

  font-size: 16px;

  line-height: 20px;

  color: color-bg(white);
}

.header-text {
  margin: 25px;
  color: color-bg(primary) !important;
  text-align: center;
}

.typography-display-large {
  font-weight: medium;
  font-size: 40px;
  line-height: 48px;
  color: color-bg(white);
}
.typography-display-medium {
  font-weight: medium;
  font-size: 32px;
  line-height: 40px;
  color: color-bg(white);
}
.typography-heading-large {
  font-weight: medium;
  font-size: 24px;
  line-height: 36px;
  color: color-bg(white);
}
.typography-heading-medium {
  font-weight: medium;
  font-size: 20px;
  line-height: 28px;
  color: color-bg(white);
}
.typography-body-large {
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: color-bg(white);
}
.typography-body-medium {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: color-bg(white);
}
.typography-body-small {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: color-bg(white);
}
.typography-label-medium {
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: color-bg(white);
}
.typography-label-small {
  font-weight: normal;
  font-size: 8px;
  line-height: 12px;
  color: color-bg(white);
}
