/* CSS */
.button-primary-1 {
  align-items: center;
  background: color-bg(primary);
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #000;
  display: flex;
  flex-direction: row;

  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  height: 45px;
  padding: 19px 24px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-delete {
  align-items: center;
  background: #ff3333;
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #000;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  height: 45px;
  padding: 19px 24px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-delete :hover {
}

.search-button {
  border-radius: 0px 10px 10px 0px !important;
  height: 40px;
  width: 80px;
  min-width: none !important;
  padding: 0px;

  div {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.button-primary-1-diabled {
  background: #dcf2f7 !important;
  box-shadow: none !important;
  cursor: default !important;
}

.button-primary-1-hovered {
  background: #00d8ff;
}

.button-primary-1:active,
.button-primary-1:hover {
  background: #00d8ff;
  outline: 0;
}

.button-primary-1 p {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.button-primary-1 div {
  display: inline-flex;
  margin-right: 5px;
  margin-left: -5px;
}

@media (min-width: 768px) {
  .button-primary-1 {
    font-size: 24px;
  }
}

.button-secondary-1 {
  align-items: center;
  background: #68baa6;
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #000;
  display: flex;
  flex-direction: row;

  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  height: 45px;
  width: 100px;
  padding: 19px 24px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-secondary-1-diabled {
  background: #dcf2f7 !important;
  box-shadow: none !important;
  cursor: default !important;
}

.button-secondary-1-hovered {
  background: #c6fced;
}

.button-secondary-1:active,
.button-secondary-1:hover {
  background: #c6fced;
  outline: 0;
}

.button-secondary-1 p {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.button-secondary-1 div {
  margin-right: 5px;
  margin-left: -5px;
}

@media (min-width: 768px) {
  .button-secondary-1 {
    font-size: 24px;
    min-width: 196px;
  }
}

.link-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: #00d8ff;
}

.link-holder:hover {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  text-decoration: underline;
}

.link-holder-hovered {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  text-decoration: underline;
}

.link-holder-disabled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: #dcf2f7;
  text-decoration: none !important;
  cursor: default;
}

.link-holder p {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  font-weight: 700;
}

.link-holder div {
  margin-right: 5px;
  margin-left: -5px;
}

.link-icon {
  margin-right: 5px;
  margin-left: -5px;
  margin-top: -2px;
}

.filter-blue {
  filter: invert(36%) sepia(61%) saturate(1405%) hue-rotate(223deg)
    brightness(98%) contrast(105%);
}

.button-primary-2 {
  align-items: center;
  background: color-bg(primary);
  border: 0;
  border-radius: 10px;
  color: #000;
  display: inline-flex;
  flex-direction: row;

  font-size: 14px;
  justify-content: center;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-primary-2-diabled {
  background: #dcf2f7 !important;
  box-shadow: none !important;
  cursor: default !important;
}

.button-primary-2-hovered {
  background: #00d8ff;
}

.button-primary-2:active,
.button-primary-2:hover {
  background: #00d8ff;
  outline: 0;
}

.button-primary-2 p {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.button-primary-2 div {
  margin-right: 5px;
  margin-left: -5px;
}

@media (min-width: 768px) {
  .button-primary-2 {
    font-size: 24px;
  }
}

.button-secondary-2 {
  align-items: center;
  background: #68baa6;
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #000;
  display: inline-flex;
  flex-direction: row;

  font-size: 14px;
  justify-content: center;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-secondary-2-diabled {
  background: #dcf2f7 !important;
  box-shadow: none !important;
  cursor: default !important;
}

.button-secondary-2-hovered {
  background: #c6fced;
}

.button-secondary-2:active,
.button-secondary-2:hover {
  background: #c6fced;
  outline: 0;
}

.button-secondary-2 p {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.button-secondary-2 div {
  margin-right: 5px;
  margin-left: -5px;
}

@media (min-width: 768px) {
  .button-secondary-2 {
    font-size: 24px;
  }
}

.icon-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
}

.icon-button-disabled {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  background-color: none !important;

  div {
    filter: invert(50%) sepia(3%) saturate(0%) hue-rotate(23deg) brightness(50%)
      contrast(40%);

    opacity: 0.5;
  }
}

.icon-button div {
  filter: invert(36%) sepia(61%) saturate(1405%) hue-rotate(223deg)
    brightness(98%) contrast(105%);
}

.icon-button:hover {
  background-color: #00d8ff;

  div {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(191deg)
      brightness(101%) contrast(101%);
  }
}

.icon-button-hover {
  background-color: #00d8ff;

  div {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(191deg)
      brightness(101%) contrast(101%);
  }
}

.button-primary-3 {
  align-items: center;
  background: #ffffff;
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #0f0f0f;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  height: 45px;
  width: 100px;
  padding: 19px 24px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-primary-3-diabled {
  background: #dcf2f7 !important;
  box-shadow: none !important;
  cursor: default !important;
}

.button-primary-3-hovered {
  background: #00d8ff;
}

.button-primary-3:active,
.button-primary-3:hover {
  background: #00d8ff;
  outline: 0;
}

.button-primary-3 p {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.button-primary-3 div {
  margin-right: 5px;
  margin-left: -5px;
}

@media (min-width: 768px) {
  .button-primary-3 {
    font-size: 24px;
  }
}
