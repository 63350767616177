.model {
  .wrap.svelte-1ka70lm {
    pointer-events: none;
    position: absolute;
    inset: 0;
    z-index: 50;
    display: flex;
    max-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .dark .cover-bg.svelte-1ka70lm {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }
  .cover-bg.svelte-1ka70lm {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
  @keyframes svelte-1ka70lm-pulse {
    50% {
      opacity: 0.5;
    }
  }
  .generating.svelte-1ka70lm {
    animation: svelte-1ka70lm-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(255 124 0 / var(--tw-border-opacity));
  }
  .progress-bar.svelte-1ka70lm {
    position: absolute;
    inset: 0;
    z-index: 10;
    transform-origin: left;
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
    opacity: 0.8;
  }
  .meta-text.svelte-1ka70lm {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    padding: 0.25rem 0.5rem;
    font-family: IBM Plex Mono, ui-monospace, SFMono-Regular, Menlo, Monaco,
      Consolas, Liberation Mono, Courier New, monospace;
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .timer.svelte-1ka70lm {
    --tw-translate-y: -4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .dark .error.svelte-1ka70lm {
    background-color: #ef44441a;
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity));
  }
  .error.svelte-1ka70lm {
    border-radius: 9999px;
    background-color: #ef44440d;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: Source Sans Pro, ui-sans-serif, system-ui, -apple-system,
      BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol,
      "Noto Color Emoji";
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity));
  }

  :before,
  :after {
    --tw-content: "";
  }
  html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    tab-size: 4;
    font-family: Source Sans Pro, ui-sans-serif, system-ui, -apple-system,
      BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol,
      "Noto Color Emoji";
  }
  body {
    margin: 0;
    line-height: inherit;
  }
  hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
  }
  abbr:where([title]) {
    text-decoration: underline dotted;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  b,
  strong {
    font-weight: bolder;
  }
  code,
  kbd,
  samp,
  pre {
    font-family: IBM Plex Mono, ui-monospace, SFMono-Regular, Menlo, Monaco,
      Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
  }
  :-moz-focusring {
    outline: auto;
  }
  :-moz-ui-invalid {
    box-shadow: none;
  }
  progress {
    vertical-align: baseline;
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  summary {
    display: list-item;
  }
  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }
  fieldset {
    margin: 0;
    padding: 0;
  }
  legend {
    padding: 0;
  }
  ol,
  ul,
  menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  textarea {
    resize: vertical;
  }
  input::placeholder,
  textarea::placeholder {
    opacity: 1;
    color: #9ca3af;
  }
  button,
  [role="button"] {
    cursor: pointer;
  }
  :disabled {
    cursor: default;
  }
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }
  img,
  video {
    max-width: 100%;
    height: auto;
  }
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    appearance: none;
    background-color: #000;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-shadow: 0 0 #0000;
  }
  [type="text"]:focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="datetime-local"]:focus,
  [type="month"]:focus,
  [type="search"]:focus,
  [type="tel"]:focus,
  [type="time"]:focus,
  [type="week"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #000;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow);
    border-color: #2563eb;
  }
  input::placeholder,
  textarea::placeholder {
    color: #6b7280;
    opacity: 1;
  }
  ::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
  }
  ::-webkit-date-and-time-value {
    min-height: 1.5em;
  }
  ::-webkit-datetime-edit,
  ::-webkit-datetime-edit-year-field,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-minute-field,
  ::-webkit-datetime-edit-second-field,
  ::-webkit-datetime-edit-millisecond-field,
  ::-webkit-datetime-edit-meridiem-field {
    padding-top: 0;
    padding-bottom: 0;
  }
  select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    color-adjust: exact;
  }
  [multiple] {
    background-image: initial;
    background-position: initial;
    background-repeat: unset;
    background-size: initial;
    padding-right: 0.75rem;
    color-adjust: unset;
  }
  [type="checkbox"],
  [type="radio"] {
    appearance: none;
    padding: 0;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #2563eb;
    background-color: #000;
    border-color: #6b7280;
    border-width: 1px;
    --tw-shadow: 0 0 #0000;
  }
  [type="checkbox"] {
    border-radius: 0;
  }
  [type="radio"] {
    border-radius: 100%;
  }
  [type="checkbox"]:focus,
  [type="radio"]:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty);
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #000;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow);
  }
  [type="checkbox"]:checked,
  [type="radio"]:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  [type="checkbox"]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }
  [type="radio"]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  }
  [type="checkbox"]:checked:hover,
  [type="checkbox"]:checked:focus,
  [type="radio"]:checked:hover,
  [type="radio"]:checked:focus {
    border-color: transparent;
    background-color: currentColor;
  }
  [type="checkbox"]:indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  [type="checkbox"]:indeterminate:hover,
  [type="checkbox"]:indeterminate:focus {
    border-color: transparent;
    background-color: currentColor;
  }
  [type="file"] {
    background: unset;
    border-color: inherit;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-size: unset;
    line-height: inherit;
  }
  [type="file"]:focus {
    outline: 1px auto -webkit-focus-ring-color;
  }
  .bg-gray-950 {
    background-color: #0b0f19;
  }
  .dark {
    background-color: #0b0f19;
    --tw-bg-opacity: 1;
    background-color: rgb(11 15 25 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
  }
  .dark .text-gray-500,
  .dark .text-gray-600,
  .dark .\!text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
  }
  .dark .text-gray-700,
  .dark .text-gray-800,
  .dark .text-gray-900,
  .dark .\!text-gray-700,
  .dark .\!text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
  }
  .dark .border,
  .dark .border-gray-100,
  .dark .border-gray-200,
  .dark .border-gray-300,
  .dark .\!border,
  .dark .\!border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .dark .bg-white {
    background-color: #0b0f19;
    --tw-bg-opacity: 1;
    background-color: rgb(11 15 25 / var(--tw-bg-opacity));
  }
  .dark .bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }
  .dark .bg-gray-200,
  .dark .\!bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }
  .unequal-height {
    align-items: flex-start;
  }
  *,
  :before,
  :after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #000;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
  }
  ::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #000;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
  }
  .container {
    width: 100%;
  }
  .\!container {
    width: 100% !important;
  }
  @media (min-width: 640px) {
    .container {
      max-width: 640px;
    }
    .\!container {
      max-width: 640px !important;
    }
  }
  @media (min-width: 768px) {
    .container {
      max-width: 768px;
    }
    .\!container {
      max-width: 768px !important;
    }
  }
  @media (min-width: 1024px) {
    .container {
      max-width: 1024px;
    }
    .\!container {
      max-width: 1024px !important;
    }
  }
  @media (min-width: 1280px) {
    .container {
      max-width: 1280px;
    }
    .\!container {
      max-width: 1280px !important;
    }
  }
  @media (min-width: 1536px) {
    .container {
      max-width: 1536px;
    }
    .\!container {
      max-width: 1536px !important;
    }
  }
  .gr-form > .gr-block {
    border-radius: 0;
    border-width: 0px;
    --tw-shadow: 0 0 #0000 !important;
    --tw-shadow-colored: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }
  .row > *,
  .row > .gr-form > * {
    min-width: min(160px, 100%);
    flex: 1 1 0%;
  }
  .col > *,
  .col > .gr-form > * {
    width: 100%;
  }
  .gr-compact > *,
  .gr-compact .gr-box {
    border-radius: 0 !important;
    border-width: 0px !important;
  }
  .scroll-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scroll-hide::-webkit-scrollbar {
    display: none;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .pointer-events-none {
    pointer-events: none;
  }
  .pointer-events-auto {
    pointer-events: auto;
  }
  .visible {
    visibility: visible;
  }
  .\!visible {
    visibility: visible !important;
  }
  .invisible {
    visibility: hidden;
  }
  .static {
    position: static;
  }
  .fixed {
    position: fixed;
  }
  .absolute {
    position: absolute;
  }
  .\!absolute {
    position: absolute !important;
  }
  .relative {
    position: relative;
  }
  .sticky {
    position: sticky;
  }
  .inset-0 {
    inset: 0;
  }
  .inset-2 {
    inset: 0.5rem;
  }
  .inset-x-0 {
    left: 0;
    right: 0;
  }
  .bottom-\[50px\] {
    bottom: 50px;
  }
  .bottom-0 {
    bottom: 0;
  }
  .left-0 {
    left: 0;
  }
  .right-0 {
    right: 0;
  }
  .top-0 {
    top: 0;
  }
  .top-7 {
    top: 1.75rem;
  }
  .left-4 {
    left: 1rem;
  }
  .right-4 {
    right: 1rem;
  }
  .top-2 {
    top: 0.5rem;
  }
  .right-2 {
    right: 0.5rem;
  }
  .top-10 {
    top: 2.5rem;
  }
  .top-\[2px\] {
    top: 2px;
  }
  .right-6 {
    right: 1.5rem;
  }
  .top-6 {
    top: 1.5rem;
  }
  .top-\[-3px\] {
    top: -3px;
  }
  .bottom-2 {
    bottom: 0.5rem;
  }
  .isolate {
    isolation: isolate;
  }
  .z-10 {
    z-index: 10;
  }
  .z-\[5\] {
    z-index: 5;
  }
  .z-20 {
    z-index: 20;
  }
  .z-\[100\] {
    z-index: 100;
  }
  .z-50 {
    z-index: 50;
  }
  .z-40 {
    z-index: 40;
  }
  .m-12 {
    margin: 3rem;
  }
  .\!m-0 {
    margin: 0 !important;
  }
  .m-auto {
    margin: auto;
  }
  .m-1 {
    margin: 0.25rem;
  }
  .m-1\.5 {
    margin: 0.375rem;
  }
  .m-0 {
    margin: 0;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .\!mt-0 {
    margin-top: 0 !important;
  }
  .\!mb-0 {
    margin-bottom: 0 !important;
  }
  .\!ml-0 {
    margin-left: 0 !important;
  }
  .\!mr-0 {
    margin-right: 0 !important;
  }
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .ml-1 {
    margin-left: 0.25rem;
  }
  .mt-6 {
    margin-top: 1.5rem;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }
  .ml-0\.5 {
    margin-left: 0.125rem;
  }
  .ml-0 {
    margin-left: 0;
  }
  .mt-8 {
    margin-top: 2rem;
  }
  .mt-4 {
    margin-top: 1rem;
  }
  .mr-1 {
    margin-right: 0.25rem;
  }
  .ml-auto {
    margin-left: auto;
  }
  .mr-2 {
    margin-right: 0.5rem;
  }
  .mt-7 {
    margin-top: 1.75rem;
  }
  .mt-3 {
    margin-top: 0.75rem;
  }
  .mb-7 {
    margin-bottom: 1.75rem;
  }
  .ml-2 {
    margin-left: 0.5rem;
  }
  .mr-\[-4px\] {
    margin-right: -4px;
  }
  .mt-\[0\.05rem\] {
    margin-top: 0.05rem;
  }
  .mb-3 {
    margin-bottom: 0.75rem;
  }
  .mr-0\.5 {
    margin-right: 0.125rem;
  }
  .mr-0 {
    margin-right: 0;
  }
  .mt-10 {
    margin-top: 2.5rem;
  }
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  .-mb-\[2px\] {
    margin-bottom: -2px;
  }
  .mt-1 {
    margin-top: 0.25rem;
  }
  .box-border {
    box-sizing: border-box;
  }
  .block {
    display: block;
  }
  .\!block {
    display: block !important;
  }
  .inline-block {
    display: inline-block;
  }
  .inline {
    display: inline;
  }
  .flex {
    display: flex;
  }
  .inline-flex {
    display: inline-flex;
  }
  .table {
    display: table;
  }
  .grid {
    display: grid;
  }
  .contents {
    display: contents;
  }
  .hidden {
    display: none;
  }
  .\!hidden {
    display: none !important;
  }
  .aspect-square {
    aspect-ratio: 1 / 1;
  }
  .h-\[22px\] {
    height: 22px;
  }
  .h-full {
    height: 100%;
  }
  .h-5 {
    height: 1.25rem;
  }
  .h-\[60px\] {
    height: 60px;
  }
  .\!h-9 {
    height: 2.25rem !important;
  }
  .h-7 {
    height: 1.75rem;
  }
  .h-96 {
    height: 24rem;
  }
  .h-0 {
    height: 0px;
  }
  .h-\[12px\] {
    height: 12px;
  }
  .h-\[60\%\] {
    height: 60%;
  }
  .h-1\.5 {
    height: 0.375rem;
  }
  .h-1 {
    height: 0.25rem;
  }
  .h-14 {
    height: 3.5rem;
  }
  .h-6 {
    height: 1.5rem;
  }
  .h-\[40vh\] {
    height: 40vh;
  }
  .h-60 {
    height: 15rem;
  }
  .h-10 {
    height: 2.5rem;
  }
  .h-2\/4 {
    height: 50%;
  }
  .h-20 {
    height: 5rem;
  }
  .h-2 {
    height: 0.5rem;
  }
  .h-\[50\%\] {
    height: 50%;
  }
  .h-4 {
    height: 1rem;
  }
  .h-3 {
    height: 0.75rem;
  }
  .max-h-\[55vh\] {
    max-height: 55vh;
  }
  .max-h-96 {
    max-height: 24rem;
  }
  .max-h-60 {
    max-height: 15rem;
  }
  .max-h-\[30rem\] {
    max-height: 30rem;
  }
  .max-h-\[15rem\] {
    max-height: 15rem;
  }
  .min-h-\[350px\] {
    min-height: 350px;
  }
  .min-h-screen {
    min-height: 100vh;
  }
  .min-h-\[15rem\] {
    min-height: 15rem;
  }
  .min-h-\[6rem\] {
    min-height: 6rem;
  }
  .min-h-\[8rem\] {
    min-height: 8rem;
  }
  .min-h-\[200px\] {
    min-height: 200px;
  }
  .min-h-\[16rem\] {
    min-height: 16rem;
  }
  .min-h-\[2\.3rem\] {
    min-height: 2.3rem;
  }
  .min-h-\[10rem\] {
    min-height: 10rem;
  }
  .w-full {
    width: 100%;
  }
  .w-1\/2 {
    width: 50%;
  }
  .\!w-9 {
    width: 2.25rem !important;
  }
  .w-\[12px\] {
    width: 12px;
  }
  .w-5 {
    width: 1.25rem;
  }
  .w-\[60\%\] {
    width: 60%;
  }
  .w-1\.5 {
    width: 0.375rem;
  }
  .w-1 {
    width: 0.25rem;
  }
  .w-6 {
    width: 1.5rem;
  }
  .w-3\/12 {
    width: 25%;
  }
  .w-5\/12 {
    width: 41.666667%;
  }
  .w-10 {
    width: 2.5rem;
  }
  .w-2\/4 {
    width: 50%;
  }
  .w-0 {
    width: 0px;
  }
  .w-20 {
    width: 5rem;
  }
  .w-4 {
    width: 1rem;
  }
  .w-2 {
    width: 0.5rem;
  }
  .w-3 {
    width: 0.75rem;
  }
  .max-w-full {
    max-width: 100%;
  }
  .max-w-none {
    max-width: none;
  }
  .\!flex-none {
    flex: none !important;
  }
  .flex-1 {
    flex: 1 1 0%;
  }
  .flex-none {
    flex: none;
  }
  .shrink-0 {
    flex-shrink: 0;
  }
  .shrink {
    flex-shrink: 1;
  }
  .flex-grow,
  .grow {
    flex-grow: 1;
  }
  .grow-0 {
    flex-grow: 0;
  }
  .\!grow-0 {
    flex-grow: 0 !important;
  }
  .table-auto {
    table-layout: auto;
  }
  .border-collapse {
    border-collapse: collapse;
  }
  .origin-left {
    transform-origin: left;
  }
  .translate-x-px {
    --tw-translate-x: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .scale-x-\[-1\] {
    --tw-scale-x: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .-scale-y-\[1\] {
    --tw-scale-y: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .\!transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }
  @keyframes ping {
    75%,
    to {
      transform: scale(2);
      opacity: 0;
    }
  }
  .animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .\!cursor-not-allowed {
    cursor: not-allowed !important;
  }
  .cursor-default {
    cursor: default;
  }
  .cursor-crosshair {
    cursor: crosshair;
  }
  .cursor-move {
    cursor: move;
  }
  .cursor-col-resize {
    cursor: col-resize;
  }
  .cursor-row-resize {
    cursor: row-resize;
  }
  .cursor-ns-resize {
    cursor: ns-resize;
  }
  .cursor-ew-resize {
    cursor: ew-resize;
  }
  .cursor-sw-resize {
    cursor: sw-resize;
  }
  .cursor-s-resize {
    cursor: s-resize;
  }
  .cursor-se-resize {
    cursor: se-resize;
  }
  .cursor-w-resize {
    cursor: w-resize;
  }
  .cursor-e-resize {
    cursor: e-resize;
  }
  .cursor-nw-resize {
    cursor: nw-resize;
  }
  .cursor-n-resize {
    cursor: n-resize;
  }
  .cursor-ne-resize {
    cursor: ne-resize;
  }
  .cursor-grab {
    cursor: grab;
  }
  .touch-none {
    touch-action: none;
  }
  .resize {
    resize: both;
  }
  .appearance-none {
    appearance: none;
  }
  .grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .flex-row {
    flex-direction: row;
  }
  .flex-col {
    flex-direction: column;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-end {
    align-items: flex-end;
  }
  .items-center {
    align-items: center;
  }
  .items-baseline {
    align-items: baseline;
  }
  .items-stretch {
    align-items: stretch;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .gap-6 {
    gap: 1.5rem;
  }
  .gap-3 {
    gap: 0.75rem;
  }
  .gap-4 {
    gap: 1rem;
  }
  .gap-2 {
    gap: 0.5rem;
  }
  .gap-px {
    gap: 1px;
  }
  .gap-1\.5 {
    gap: 0.375rem;
  }
  .gap-1 {
    gap: 0.25rem;
  }
  .gap-0 {
    gap: 0px;
  }
  .space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .place-self-start {
    place-self: start;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .overflow-clip {
    overflow: clip;
  }
  .\!overflow-visible {
    overflow: visible !important;
  }
  .overflow-x-auto {
    overflow-x: auto;
  }
  .overflow-y-auto {
    overflow-y: auto;
  }
  .overflow-x-scroll {
    overflow-x: scroll;
  }
  .overflow-y-scroll {
    overflow-y: scroll;
  }
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .whitespace-nowrap {
    white-space: nowrap;
  }
  .break-all {
    word-break: break-all;
  }
  .rounded {
    border-radius: 0.25rem;
  }
  .rounded-lg {
    border-radius: 0.5rem;
  }
  .rounded-xl {
    border-radius: 0.75rem;
  }
  .rounded-full {
    border-radius: 9999px;
  }
  .rounded-sm {
    border-radius: 0.125rem;
  }
  .rounded-\[22px\] {
    border-radius: 22px;
  }
  .rounded-none {
    border-radius: 0;
  }
  .\!rounded-none {
    border-radius: 0 !important;
  }
  .\!rounded-lg {
    border-radius: 0.5rem !important;
  }
  .rounded-md {
    border-radius: 0.375rem;
  }
  .rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .\!rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }
  .\!rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }
  .\!rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }
  .\!rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }
  .\!rounded-tr-none {
    border-top-right-radius: 0 !important;
  }
  .\!rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }
  .\!rounded-tl-none {
    border-top-left-radius: 0 !important;
  }
  .\!rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }
  .rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .border {
    border-width: 1px;
  }
  .border-2 {
    border-width: 2px;
  }
  .\!border-0 {
    border-width: 0px !important;
  }
  .border-0 {
    border-width: 0px;
  }
  .\!border {
    border-width: 1px !important;
  }
  .\!border-t-0 {
    border-top-width: 0px !important;
  }
  .\!border-b-0 {
    border-bottom-width: 0px !important;
  }
  .\!border-l-0 {
    border-left-width: 0px !important;
  }
  .\!border-r-0 {
    border-right-width: 0px !important;
  }
  .border-b {
    border-bottom-width: 1px;
  }
  .border-t {
    border-top-width: 1px;
  }
  .border-l {
    border-left-width: 1px;
  }
  .border-r {
    border-right-width: 1px;
  }
  .border-t-0 {
    border-top-width: 0px;
  }
  .border-b-2 {
    border-bottom-width: 2px;
  }
  .border-b-0 {
    border-bottom-width: 0px;
  }
  .border-solid {
    border-style: solid;
  }
  .border-dashed {
    border-style: dashed;
  }
  .\!border-none {
    border-style: none !important;
  }
  .border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity));
  }
  .border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }
  .border-black {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity));
  }
  .border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgb(255 124 0 / var(--tw-border-opacity));
  }
  .border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
  }
  .border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgb(74 222 128 / var(--tw-border-opacity));
  }
  .border-gray-200\/60 {
    border-color: #e5e7eb99;
  }
  .border-transparent {
    border-color: transparent;
  }
  .border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgb(255 216 180 / var(--tw-border-opacity));
  }
  .\!border-red-300 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
  }
  .\!border-yellow-300 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(253 224 71 / var(--tw-border-opacity)) !important;
  }
  .\!border-green-300 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
  }
  .\!border-blue-300 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
  }
  .\!border-purple-300 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(216 180 254 / var(--tw-border-opacity)) !important;
  }
  .\!border-gray-300 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
  }
  .\!border-pink-300 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(249 168 212 / var(--tw-border-opacity)) !important;
  }
  .border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity));
  }
  .border-b-slate-300 {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(203 213 225 / var(--tw-border-opacity));
  }
  .border-r-slate-300 {
    --tw-border-opacity: 1;
    border-right-color: rgb(203 213 225 / var(--tw-border-opacity));
  }
  .\!bg-red-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-green-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-blue-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-yellow-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 240 138 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-purple-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(233 213 255 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-teal-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(153 246 228 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-orange-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 216 180 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-cyan-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(165 243 252 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-lime-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(217 249 157 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-pink-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(251 207 232 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-gray-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
  }
  .bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  }
  .bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  }
  .bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  }
  .bg-gray-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(11 15 25 / var(--tw-bg-opacity));
  }
  .bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 158 11 / var(--tw-bg-opacity));
  }
  .bg-white\/90 {
    background-color: #ffffffe6;
  }
  .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
  .bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  }
  .bg-red-500\/10 {
    background-color: #ef44441a;
  }
  .bg-red-500\/5 {
    background-color: #ef44440d;
  }
  .\!bg-red-500\/10 {
    background-color: #ef44441a !important;
  }
  .bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 113 113 / var(--tw-bg-opacity));
  }
  .bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity));
  }
  .bg-black\/90 {
    background-color: #000000e6;
  }
  .bg-transparent {
    background-color: transparent;
  }
  .bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 242 229 / var(--tw-bg-opacity));
  }
  .\!bg-transparent {
    background-color: transparent !important;
  }
  .\!bg-red-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-yellow-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-green-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-blue-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-purple-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-gray-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
  }
  .\!bg-pink-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(252 231 243 / var(--tw-bg-opacity)) !important;
  }
  .bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }
  .bg-slate-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity));
  }
  .bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(217 119 6 / var(--tw-bg-opacity));
  }
  .bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity));
  }
  .bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }
  .bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }
  .bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }
  .bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .from-red-500\/5 {
    --tw-gradient-from: rgb(239 68 68 / 0.05);
    --tw-gradient-to: rgb(239 68 68 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .from-red-500\/10 {
    --tw-gradient-from: rgb(239 68 68 / 0.1);
    --tw-gradient-to: rgb(239 68 68 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .from-orange-400 {
    --tw-gradient-from: #ff9633;
    --tw-gradient-to: rgb(255 150 51 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-to: rgb(249 250 251 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .from-orange-200\/70 {
    --tw-gradient-from: rgb(255 216 180 / 0.7);
    --tw-gradient-to: rgb(255 216 180 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .from-gray-100\/70 {
    --tw-gradient-from: rgb(243 244 246 / 0.7);
    --tw-gradient-to: rgb(243 244 246 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .\!from-red-100 {
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-to: rgb(254 226 226 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .\!from-yellow-100 {
    --tw-gradient-from: #fef9c3 !important;
    --tw-gradient-to: rgb(254 249 195 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .\!from-green-100 {
    --tw-gradient-from: #dcfce7 !important;
    --tw-gradient-to: rgb(220 252 231 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .\!from-blue-100 {
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-to: rgb(219 234 254 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .\!from-purple-100 {
    --tw-gradient-from: #f3e8ff !important;
    --tw-gradient-to: rgb(243 232 255 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .\!from-gray-100 {
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-to: rgb(243 244 246 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .\!from-pink-100 {
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-to: rgb(252 231 243 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .from-\[rgba\(255\,255\,255\,0\)\] {
    --tw-gradient-from: rgba(255, 255, 255, 0);
    --tw-gradient-to: rgb(255 255 255 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .from-amber-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-to: rgb(251 191 36 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .via-transparent {
    --tw-gradient-to: rgb(0 0 0 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to);
  }
  .to-transparent {
    --tw-gradient-to: transparent;
  }
  .to-red-200\/10 {
    --tw-gradient-to: rgb(254 202 202 / 0.1);
  }
  .to-orange-200 {
    --tw-gradient-to: #ffd8b4;
  }
  .to-white {
    --tw-gradient-to: #fff;
  }
  .to-orange-300\/80 {
    --tw-gradient-to: rgb(255 176 102 / 0.8);
  }
  .to-gray-200\/80 {
    --tw-gradient-to: rgb(229 231 235 / 0.8);
  }
  .\!to-red-200 {
    --tw-gradient-to: #fecaca !important;
  }
  .\!to-yellow-200 {
    --tw-gradient-to: #fef08a !important;
  }
  .\!to-green-200 {
    --tw-gradient-to: #bbf7d0 !important;
  }
  .\!to-blue-200 {
    --tw-gradient-to: #bfdbfe !important;
  }
  .\!to-purple-200 {
    --tw-gradient-to: #e9d5ff !important;
  }
  .\!to-gray-200 {
    --tw-gradient-to: #e5e7eb !important;
  }
  .\!to-pink-200 {
    --tw-gradient-to: #fbcfe8 !important;
  }
  .to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .to-amber-500 {
    --tw-gradient-to: #f59e0b;
  }
  .fill-current {
    fill: currentColor;
  }
  .object-contain {
    object-fit: contain;
  }
  .object-cover {
    object-fit: cover;
  }
  .object-fill {
    object-fit: fill;
  }
  .p-6 {
    padding: 1.5rem;
  }
  .p-4 {
    padding: 1rem;
  }
  .p-2 {
    padding: 0.5rem;
  }
  .p-3 {
    padding: 0.75rem;
  }
  .p-1 {
    padding: 0.25rem;
  }
  .p-0 {
    padding: 0;
  }
  .p-2\.5 {
    padding: 0.625rem;
  }
  .\!p-0 {
    padding: 0 !important;
  }
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .px-\[0\.325rem\] {
    padding-left: 0.325rem;
    padding-right: 0.325rem;
  }
  .py-\[0\.05rem\] {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem;
  }
  .py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .px-\[0\.4rem\] {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .pb-6 {
    padding-bottom: 1.5rem;
  }
  .pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .pb-0 {
    padding-bottom: 0;
  }
  .pt-6 {
    padding-top: 1.5rem;
  }
  .pl-4 {
    padding-left: 1rem;
  }
  .pr-1 {
    padding-right: 0.25rem;
  }
  .pt-2 {
    padding-top: 0.5rem;
  }
  .pb-\[0\.225rem\] {
    padding-bottom: 0.225rem;
  }
  .pt-\[0\.15rem\] {
    padding-top: 0.15rem;
  }
  .pb-2 {
    padding-bottom: 0.5rem;
  }
  .pt-1\.5 {
    padding-top: 0.375rem;
  }
  .pt-1 {
    padding-top: 0.25rem;
  }
  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .text-justify {
    text-align: justify;
  }
  .font-mono {
    font-family: IBM Plex Mono, ui-monospace, SFMono-Regular, Menlo, Monaco,
      Consolas, Liberation Mono, Courier New, monospace;
  }
  .font-sans {
    font-family: Source Sans Pro, ui-sans-serif, system-ui, -apple-system,
      BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol,
      "Noto Color Emoji";
  }
  .text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .text-\[0\.855rem\] {
    font-size: 0.855rem;
  }
  .text-\[10px\] {
    font-size: 10px;
  }
  .text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .font-semibold {
    font-weight: 600;
  }
  .font-bold {
    font-weight: 700;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .lowercase {
    text-transform: lowercase;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .italic {
    font-style: italic;
  }
  .ordinal {
    --tw-ordinal: ordinal;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .leading-7 {
    line-height: 1.75rem;
  }
  .leading-tight {
    line-height: 1.25;
  }
  .leading-none {
    line-height: 1;
  }
  .leading-snug {
    line-height: 1.375;
  }
  .\!text-red-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
  }
  .\!text-green-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(34 197 94 / var(--tw-text-opacity)) !important;
  }
  .\!text-blue-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(59 130 246 / var(--tw-text-opacity)) !important;
  }
  .\!text-yellow-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(234 179 8 / var(--tw-text-opacity)) !important;
  }
  .\!text-purple-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(168 85 247 / var(--tw-text-opacity)) !important;
  }
  .\!text-teal-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(20 184 166 / var(--tw-text-opacity)) !important;
  }
  .\!text-orange-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(255 124 0 / var(--tw-text-opacity)) !important;
  }
  .\!text-cyan-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(6 182 212 / var(--tw-text-opacity)) !important;
  }
  .\!text-lime-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(132 204 22 / var(--tw-text-opacity)) !important;
  }
  .\!text-pink-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(236 72 153 / var(--tw-text-opacity)) !important;
  }
  .\!text-gray-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
  }
  .\!text-gray-700 {
    --tw-text-opacity: 1 !important;
    color: rgb(55 65 81 / var(--tw-text-opacity)) !important;
  }
  .text-amber-500 {
    --tw-text-opacity: 1;
    color: rgb(245 158 11 / var(--tw-text-opacity));
  }
  .text-pink-400 {
    --tw-text-opacity: 1;
    color: rgb(244 114 182 / var(--tw-text-opacity));
  }
  .text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
  }
  .text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
  }
  .text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
  }
  .text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity));
  }
  .text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
  }
  .text-blue-500 {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
  }
  .text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity));
  }
  .text-red-400 {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity));
  }
  .text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity));
  }
  .text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity));
  }
  .text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
  .text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
  .text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity));
  }
  .text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
  }
  .text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
  }
  .text-orange-500 {
    --tw-text-opacity: 1;
    color: rgb(255 124 0 / var(--tw-text-opacity));
  }
  .text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity));
  }
  .text-orange-600 {
    --tw-text-opacity: 1;
    color: rgb(238 116 0 / var(--tw-text-opacity));
  }
  .\!text-gray-800 {
    --tw-text-opacity: 1 !important;
    color: rgb(31 41 55 / var(--tw-text-opacity)) !important;
  }
  .underline {
    text-decoration-line: underline;
  }
  .opacity-50 {
    opacity: 0.5;
  }
  .opacity-0 {
    opacity: 0;
  }
  .opacity-80 {
    opacity: 0.8;
  }
  .opacity-75 {
    opacity: 0.75;
  }
  .opacity-20 {
    opacity: 0.2;
  }
  .opacity-40 {
    opacity: 0.4;
  }
  .opacity-90 {
    opacity: 0.9;
  }
  .shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
      0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\!shadow-none {
    --tw-shadow: 0 0 #0000 !important;
    --tw-shadow-colored: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }
  .shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
      0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .shadow-red-500\/10 {
    --tw-shadow-color: rgb(239 68 68 / 0.1);
    --tw-shadow: var(--tw-shadow-colored);
  }
  .outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .outline {
    outline-style: solid;
  }
  .\!ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000) !important;
  }
  .ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .ring-inset {
    --tw-ring-inset: inset;
  }
  .\!ring-orange-500 {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgb(255 124 0 / var(--tw-ring-opacity)) !important;
  }
  .ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
  }
  .ring-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 124 0 / var(--tw-ring-opacity));
  }
  .blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
      drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .grayscale {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .sepia {
    --tw-sepia: sepia(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .backdrop-blur {
    --tw-backdrop-blur: blur(8px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
      var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
      var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
      var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
      var(--tw-backdrop-sepia);
  }
  .transition-colors {
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .transition {
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, opacity, box-shadow, transform,
      filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .duration-75 {
    transition-duration: 75ms;
  }
  .duration-500 {
    transition-duration: 0.5s;
  }
  .ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .gradio-container {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    tab-size: 4;
    font-family: Source Sans Pro, ui-sans-serif, system-ui, -apple-system,
      BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol,
      "Noto Color Emoji";
  }
  .cropper-container {
    direction: ltr;
    font-size: 0;
    line-height: 0;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .cropper-container img {
    display: block;
    height: 100%;
    image-orientation: 0deg;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 100%;
  }
  .cropper-wrap-box,
  .cropper-canvas,
  .cropper-drag-box,
  .cropper-crop-box,
  .cropper-modal {
    inset: 0;
    position: absolute;
  }
  .cropper-wrap-box,
  .cropper-canvas {
    overflow: hidden;
  }
  .cropper-drag-box {
    background-color: #000;
    opacity: 0;
  }
  .cropper-modal {
    background-color: #000;
    opacity: 0.5;
  }
  .cropper-view-box {
    display: block;
    height: 100%;
    outline: 1px solid #39f;
    outline-color: #3399ffbf;
    overflow: hidden;
    width: 100%;
  }
  .cropper-dashed {
    border: 0 dashed #eee;
    display: block;
    opacity: 0.5;
    position: absolute;
  }
  .cropper-dashed.dashed-h {
    border-bottom-width: 1px;
    border-top-width: 1px;
    height: calc(100% / 3);
    left: 0;
    top: calc(100% / 3);
    width: 100%;
  }
  .cropper-dashed.dashed-v {
    border-left-width: 1px;
    border-right-width: 1px;
    height: 100%;
    left: calc(100% / 3);
    top: 0;
    width: calc(100% / 3);
  }
  .cropper-center {
    display: block;
    height: 0;
    left: 50%;
    opacity: 0.75;
    position: absolute;
    top: 50%;
    width: 0;
  }
  .cropper-center:before,
  .cropper-center:after {
    background-color: #eee;
    content: " ";
    display: block;
    position: absolute;
  }
  .cropper-center:before {
    height: 1px;
    left: -3px;
    top: 0;
    width: 7px;
  }
  .cropper-center:after {
    height: 7px;
    left: 0;
    top: -3px;
    width: 1px;
  }
  .cropper-face,
  .cropper-line,
  .cropper-point {
    display: block;
    height: 100%;
    opacity: 0.1;
    position: absolute;
    width: 100%;
  }
  .cropper-face {
    background-color: #000;
    left: 0;
    top: 0;
  }
  .cropper-line {
    background-color: #39f;
  }
  .cropper-line.line-e {
    cursor: ew-resize;
    right: -3px;
    top: 0;
    width: 5px;
  }
  .cropper-line.line-n {
    cursor: ns-resize;
    height: 5px;
    left: 0;
    top: -3px;
  }
  .cropper-line.line-w {
    cursor: ew-resize;
    left: -3px;
    top: 0;
    width: 5px;
  }
  .cropper-line.line-s {
    bottom: -3px;
    cursor: ns-resize;
    height: 5px;
    left: 0;
  }
  .cropper-point {
    background-color: #39f;
    height: 5px;
    opacity: 0.75;
    width: 5px;
  }
  .cropper-point.point-e {
    cursor: ew-resize;
    margin-top: -3px;
    right: -3px;
    top: 50%;
  }
  .cropper-point.point-n {
    cursor: ns-resize;
    left: 50%;
    margin-left: -3px;
    top: -3px;
  }
  .cropper-point.point-w {
    cursor: ew-resize;
    left: -3px;
    margin-top: -3px;
    top: 50%;
  }
  .cropper-point.point-s {
    bottom: -3px;
    cursor: s-resize;
    left: 50%;
    margin-left: -3px;
  }
  .cropper-point.point-ne {
    cursor: nesw-resize;
    right: -3px;
    top: -3px;
  }
  .cropper-point.point-nw {
    cursor: nwse-resize;
    left: -3px;
    top: -3px;
  }
  .cropper-point.point-sw {
    bottom: -3px;
    cursor: nesw-resize;
    left: -3px;
  }
  .cropper-point.point-se {
    bottom: -3px;
    cursor: nwse-resize;
    height: 20px;
    opacity: 1;
    right: -3px;
    width: 20px;
  }
  @media (min-width: 768px) {
    .cropper-point.point-se {
      height: 15px;
      width: 15px;
    }
  }
  @media (min-width: 992px) {
    .cropper-point.point-se {
      height: 10px;
      width: 10px;
    }
  }
  @media (min-width: 1200px) {
    .cropper-point.point-se {
      height: 5px;
      opacity: 0.75;
      width: 5px;
    }
  }
  .cropper-point.point-se:before {
    background-color: #39f;
    bottom: -50%;
    content: " ";
    display: block;
    height: 200%;
    opacity: 0;
    position: absolute;
    right: -50%;
    width: 200%;
  }
  .cropper-invisible {
    opacity: 0;
  }
  .cropper-bg {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
  }
  .cropper-hide {
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }
  .cropper-hidden {
    display: none !important;
  }
  .cropper-move {
    cursor: move;
  }
  .cropper-crop {
    cursor: crosshair;
  }
  .cropper-disabled .cropper-drag-box,
  .cropper-disabled .cropper-face,
  .cropper-disabled .cropper-line,
  .cropper-disabled .cropper-point {
    cursor: not-allowed;
  }
  .placeholder\:text-gray-400::placeholder {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
  .first\:rounded-t:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .last\:mb-0:last-child {
    margin-bottom: 0;
  }
  .last\:rounded-b:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .last\:border-none:last-child {
    border-style: none;
  }
  .dark .odd\:bg-gray-50:nth-child(odd) {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }
  .odd\:bg-gray-50:nth-child(odd) {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  }
  .checked\:shadow-inner:checked {
    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .focus-within\:bg-orange-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgb(255 242 229 / var(--tw-bg-opacity));
  }
  .focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .dark .hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
  }
  .dark .hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
  }
  .dark .hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .dark .hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }
  .hover\:cursor-none:hover {
    cursor: none;
  }
  .hover\:divide-orange-100:hover > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 229 204 / var(--tw-divide-opacity));
  }
  .hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
  }
  .hover\:border-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 150 51 / var(--tw-border-opacity));
  }
  .hover\:bg-amber-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(251 191 36 / var(--tw-bg-opacity));
  }
  .hover\:bg-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 242 229 / var(--tw-bg-opacity));
  }
  .hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  }
  .hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  }
  .hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-to: rgb(243 244 246 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .hover\:to-orange-200\/90:hover {
    --tw-gradient-to: rgb(255 216 180 / 0.9);
  }
  .hover\:to-gray-100\/90:hover {
    --tw-gradient-to: rgb(243 244 246 / 0.9);
  }
  .hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
  .hover\:text-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgb(255 124 0 / var(--tw-text-opacity));
  }
  .hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
  }
  .hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
  }
  .hover\:underline:hover {
    text-decoration-line: underline;
  }
  .hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
      0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
      0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
      0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
      0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .hover\:ring:hover {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .hover\:ring-1:hover {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .hover\:\!ring-orange-500:hover {
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgb(255 124 0 / var(--tw-ring-opacity)) !important;
  }
  .hover\:ring-orange-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 176 102 / var(--tw-ring-opacity));
  }
  .hover\:brightness-110:hover {
    --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-border-opacity));
  }
  .focus\:bg-gradient-to-b:focus {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }
  .focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-to: rgb(219 234 254 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
  }
  .focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity));
  }
  .focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .dark .focus\:odd\:bg-white:nth-child(odd):focus {
    background-color: #0b0f19;
    --tw-bg-opacity: 1;
    background-color: rgb(11 15 25 / var(--tw-bg-opacity));
  }
  .focus\:odd\:bg-white:nth-child(odd):focus {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
  .active\:shadow-inner:active {
    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .disabled\:cursor-not-allowed:disabled {
    cursor: not-allowed;
  }
  .disabled\:\!cursor-not-allowed:disabled {
    cursor: not-allowed !important;
  }
  .disabled\:text-gray-400:disabled {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
  .group:last-child .group-last\:first\:rounded-bl-lg:first-child {
    border-bottom-left-radius: 0.5rem;
  }
  .group:last-child .group-last\:last\:rounded-br-lg:last-child {
    border-bottom-right-radius: 0.5rem;
  }
  .group:hover .group-hover\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgb(255 150 51 / var(--tw-border-opacity));
  }
  .group:hover .group-hover\:from-orange-500 {
    --tw-gradient-from: #ff7c00;
    --tw-gradient-to: rgb(255 124 0 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .group:hover .group-hover\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgb(255 124 0 / var(--tw-text-opacity));
  }
  .group:hover .group-hover\:opacity-100 {
    opacity: 1;
  }
  .group:hover .group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .group-hover\:grayscale-0 {
    --tw-grayscale: grayscale(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .dark .dark\:bg-gray-950 {
    background-color: #0b0f19;
  }
  .dark .dark .dark\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
  }
  .dark .dark\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-divide-opacity));
  }
  .dark .dark\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-divide-opacity));
  }
  .dark .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .dark .dark\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity));
  }
  .dark .dark\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
  }
  .dark .dark\:\!border-red-900 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(127 29 29 / var(--tw-border-opacity)) !important;
  }
  .dark .dark\:\!border-yellow-900 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(113 63 18 / var(--tw-border-opacity)) !important;
  }
  .dark .dark\:\!border-green-900 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(20 83 45 / var(--tw-border-opacity)) !important;
  }
  .dark .dark\:\!border-blue-900 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(30 58 138 / var(--tw-border-opacity)) !important;
  }
  .dark .dark\:\!border-purple-900 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(88 28 135 / var(--tw-border-opacity)) !important;
  }
  .dark .dark\:\!border-gray-900 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
  }
  .dark .dark\:\!border-pink-900 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(131 24 67 / var(--tw-border-opacity)) !important;
  }
  .dark .dark\:border-slate-300 {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity));
  }
  .dark .dark\:border-b-slate-700 {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(51 65 85 / var(--tw-border-opacity));
  }
  .dark .dark\:border-r-slate-700 {
    --tw-border-opacity: 1;
    border-right-color: rgb(51 65 85 / var(--tw-border-opacity));
  }
  .dark .dark\:\!bg-red-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(185 28 28 / var(--tw-bg-opacity)) !important;
  }
  .dark .dark\:\!bg-green-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(21 128 61 / var(--tw-bg-opacity)) !important;
  }
  .dark .dark\:\!bg-blue-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity)) !important;
  }
  .dark .dark\:\!bg-yellow-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(161 98 7 / var(--tw-bg-opacity)) !important;
  }
  .dark .dark\:\!bg-purple-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(126 34 206 / var(--tw-bg-opacity)) !important;
  }
  .dark .dark\:\!bg-teal-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(15 118 110 / var(--tw-bg-opacity)) !important;
  }
  .dark .dark\:\!bg-orange-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(206 100 0 / var(--tw-bg-opacity)) !important;
  }
  .dark .dark\:\!bg-cyan-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(14 116 144 / var(--tw-bg-opacity)) !important;
  }
  .dark .dark\:\!bg-lime-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(77 124 15 / var(--tw-bg-opacity)) !important;
  }
  .dark .dark\:\!bg-pink-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(190 24 93 / var(--tw-bg-opacity)) !important;
  }
  .dark .dark\:\!bg-gray-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity)) !important;
  }
  .dark .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }
  .dark .dark\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  }
  .dark .dark\:bg-gray-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(11 15 25 / var(--tw-bg-opacity));
  }
  .dark .dark\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }
  .dark .dark\:bg-transparent {
    background-color: transparent;
  }
  .dark .dark\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  }
  .dark .dark\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity));
  }
  .dark .dark\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity));
  }
  .dark .dark\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .dark .dark\:from-orange-400 {
    --tw-gradient-from: #ff9633;
    --tw-gradient-to: rgb(255 150 51 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .dark .dark\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-to: rgb(17 24 39 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .dark .dark\:from-orange-700 {
    --tw-gradient-from: #ce6400;
    --tw-gradient-to: rgb(206 100 0 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .dark .dark\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-to: rgb(75 85 99 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .dark .dark\:\!from-red-700 {
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-to: rgb(185 28 28 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .dark .dark\:\!from-yellow-700 {
    --tw-gradient-from: #a16207 !important;
    --tw-gradient-to: rgb(161 98 7 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .dark .dark\:\!from-green-700 {
    --tw-gradient-from: #15803d !important;
    --tw-gradient-to: rgb(21 128 61 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .dark .dark\:\!from-blue-700 {
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-to: rgb(29 78 216 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .dark .dark\:\!from-purple-700 {
    --tw-gradient-from: #7e22ce !important;
    --tw-gradient-to: rgb(126 34 206 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .dark .dark\:\!from-gray-700 {
    --tw-gradient-from: #374151 !important;
    --tw-gradient-to: rgb(55 65 81 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .dark .dark\:\!from-pink-700 {
    --tw-gradient-from: #be185d !important;
    --tw-gradient-to: rgb(190 24 93 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .dark .dark\:from-\[rgba\(0\,0\,0\,0\)\] {
    --tw-gradient-from: rgba(0, 0, 0, 0);
    --tw-gradient-to: rgb(0 0 0 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .dark .dark\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-to: rgb(239 68 68 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .dark .dark\:to-orange-600 {
    --tw-gradient-to: #ee7400;
  }
  .dark .dark\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .dark .dark\:to-orange-700 {
    --tw-gradient-to: #ce6400;
  }
  .dark .dark\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .dark .dark\:\!to-red-800 {
    --tw-gradient-to: #991b1b !important;
  }
  .dark .dark\:\!to-yellow-800 {
    --tw-gradient-to: #854d0e !important;
  }
  .dark .dark\:\!to-green-800 {
    --tw-gradient-to: #166534 !important;
  }
  .dark .dark\:\!to-blue-800 {
    --tw-gradient-to: #1e40af !important;
  }
  .dark .dark\:\!to-purple-800 {
    --tw-gradient-to: #6b21a8 !important;
  }
  .dark .dark\:\!to-gray-800 {
    --tw-gradient-to: #1f2937 !important;
  }
  .dark .dark\:\!to-pink-800 {
    --tw-gradient-to: #9d174d !important;
  }
  .dark .dark\:to-gray-950 {
    --tw-gradient-to: #0b0f19;
  }
  .dark .dark\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .dark .dark\:fill-slate-200 {
    fill: #e2e8f0;
  }
  .dark .dark\:\!text-red-300 {
    --tw-text-opacity: 1 !important;
    color: rgb(252 165 165 / var(--tw-text-opacity)) !important;
  }
  .dark .dark\:\!text-green-300 {
    --tw-text-opacity: 1 !important;
    color: rgb(134 239 172 / var(--tw-text-opacity)) !important;
  }
  .dark .dark\:\!text-blue-300 {
    --tw-text-opacity: 1 !important;
    color: rgb(147 197 253 / var(--tw-text-opacity)) !important;
  }
  .dark .dark\:\!text-yellow-300 {
    --tw-text-opacity: 1 !important;
    color: rgb(253 224 71 / var(--tw-text-opacity)) !important;
  }
  .dark .dark\:\!text-purple-300 {
    --tw-text-opacity: 1 !important;
    color: rgb(216 180 254 / var(--tw-text-opacity)) !important;
  }
  .dark .dark\:\!text-teal-300 {
    --tw-text-opacity: 1 !important;
    color: rgb(94 234 212 / var(--tw-text-opacity)) !important;
  }
  .dark .dark\:\!text-orange-300 {
    --tw-text-opacity: 1 !important;
    color: rgb(255 176 102 / var(--tw-text-opacity)) !important;
  }
  .dark .dark\:\!text-cyan-300 {
    --tw-text-opacity: 1 !important;
    color: rgb(103 232 249 / var(--tw-text-opacity)) !important;
  }
  .dark .dark\:\!text-lime-300 {
    --tw-text-opacity: 1 !important;
    color: rgb(190 242 100 / var(--tw-text-opacity)) !important;
  }
  .dark .dark\:\!text-pink-300 {
    --tw-text-opacity: 1 !important;
    color: rgb(249 168 212 / var(--tw-text-opacity)) !important;
  }
  .dark .dark\:\!text-gray-300 {
    --tw-text-opacity: 1 !important;
    color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
  }
  .dark .dark\:\!text-gray-50 {
    --tw-text-opacity: 1 !important;
    color: rgb(249 250 251 / var(--tw-text-opacity)) !important;
  }
  .dark .dark\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgb(219 39 119 / var(--tw-text-opacity));
  }
  .dark .dark\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
  }
  .dark .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .dark .dark\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
  }
  .dark .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
  .dark .dark\:text-slate-200 {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
  }
  .dark .dark\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgb(165 180 252 / var(--tw-text-opacity));
  }
  .dark .dark\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity));
  }
  .dark .dark\:text-slate-300 {
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity));
  }
  .dark .dark\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgb(254 226 226 / var(--tw-text-opacity));
  }
  .dark .dark\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgb(254 249 195 / var(--tw-text-opacity));
  }
  .dark .dark\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgb(220 252 231 / var(--tw-text-opacity));
  }
  .dark .dark\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgb(219 234 254 / var(--tw-text-opacity));
  }
  .dark .dark\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgb(243 232 255 / var(--tw-text-opacity));
  }
  .dark .dark\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgb(249 250 251 / var(--tw-text-opacity));
  }
  .dark .dark\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity));
  }
  .dark .dark .dark\:placeholder\:text-gray-500::placeholder {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
  }
  .dark .dark\:placeholder\:text-gray-500::placeholder {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
  }
  .dark .dark\:odd\:bg-gray-900:nth-child(odd) {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }
  .dark .dark\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }
  .dark .dark\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
  }
  .dark .dark\:hover\:border-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(206 100 0 / var(--tw-border-opacity));
  }
  .dark .dark\:hover\:bg-amber-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(217 119 6 / var(--tw-bg-opacity));
  }
  .dark .dark\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  }
  .dark .dark\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }
  .dark .dark\:hover\:to-orange-500:hover {
    --tw-gradient-to: #ff7c00;
  }
  .dark .dark\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .dark .dark\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
  .dark .dark\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
  }
  .dark .dark\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-to: rgb(30 58 138 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .dark .dark\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }
  .dark .dark\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .dark .dark\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity));
  }
  @media (min-width: 640px) {
    .sm\:grid-cols-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .sm\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .sm\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .sm\:grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .sm\:grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .sm\:grid-cols-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .sm\:grid-cols-7 {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .sm\:grid-cols-8 {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .sm\:grid-cols-9 {
      grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    .sm\:grid-cols-10 {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    .sm\:grid-cols-11 {
      grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    .sm\:grid-cols-12 {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
  @media (min-width: 768px) {
    .md\:left-auto {
      left: auto;
    }
    .md\:right-8 {
      right: 2rem;
    }
    .md\:bottom-4 {
      bottom: 1rem;
    }
    .md\:min-h-\[15rem\] {
      min-height: 15rem;
    }
    .md\:w-1\/2 {
      width: 50%;
    }
    .md\:w-96 {
      width: 24rem;
    }
    .md\:grid-cols-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .md\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .md\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .md\:grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .md\:grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .md\:grid-cols-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .md\:grid-cols-7 {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .md\:grid-cols-8 {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .md\:grid-cols-9 {
      grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    .md\:grid-cols-10 {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    .md\:grid-cols-11 {
      grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    .md\:grid-cols-12 {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .md\:bg-gradient-to-bl {
      background-image: linear-gradient(
        to bottom left,
        var(--tw-gradient-stops)
      );
    }
    .md\:text-xl {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  @media (min-width: 1024px) {
    .lg\:grid-cols-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .lg\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .lg\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .lg\:grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .lg\:grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .lg\:grid-cols-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .lg\:grid-cols-7 {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .lg\:grid-cols-8 {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .lg\:grid-cols-9 {
      grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    .lg\:grid-cols-10 {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    .lg\:grid-cols-11 {
      grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    .lg\:grid-cols-12 {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
  @media (min-width: 1280px) {
    .xl\:bottom-8 {
      bottom: 2rem;
    }
    .xl\:max-h-\[18rem\] {
      max-height: 18rem;
    }
    .xl\:min-h-\[450px\] {
      min-height: 450px;
    }
    .xl\:grid-cols-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .xl\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .xl\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .xl\:grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .xl\:grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .xl\:grid-cols-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .xl\:grid-cols-7 {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .xl\:grid-cols-8 {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .xl\:grid-cols-9 {
      grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    .xl\:grid-cols-10 {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    .xl\:grid-cols-11 {
      grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    .xl\:grid-cols-12 {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
  @media (min-width: 1536px) {
    .\32xl\:max-h-\[20rem\] {
      max-height: 20rem;
    }
    .\32xl\:grid-cols-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .\32xl\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .\32xl\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .\32xl\:grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .\32xl\:grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .\32xl\:grid-cols-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .\32xl\:grid-cols-7 {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .\32xl\:grid-cols-8 {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .\32xl\:grid-cols-9 {
      grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    .\32xl\:grid-cols-10 {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    .\32xl\:grid-cols-11 {
      grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    .\32xl\:grid-cols-12 {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
  .dark .gr-box {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
    background-color: #0b0f19;
    --tw-bg-opacity: 1;
    background-color: rgb(11 15 25 / var(--tw-bg-opacity));
  }
  .gr-box {
    position: relative;
    border-radius: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .dark .gr-box {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }
  .dark .gr-box-unrounded {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
    background-color: #0b0f19;
    --tw-bg-opacity: 1;
    background-color: rgb(11 15 25 / var(--tw-bg-opacity));
  }
  .gr-box-unrounded {
    position: relative;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .dark .gr-box-unrounded {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }
  .dark .gr-input {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .gr-input {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }
  .gr-input::placeholder {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
  .gr-input:checked {
    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .gr-input:focus {
    --tw-border-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-border-opacity));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
    --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity));
    --tw-ring-opacity: 0.5;
  }
  .dark .gr-input {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
  }
  .dark .dark .gr-input::placeholder {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
  }
  .dark .gr-input::placeholder {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
  }
  .dark .gr-input:focus {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .dark .gr-label {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
  }
  .gr-label {
    margin-bottom: 0.5rem;
    display: block;
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
  }
  .gr-padded {
    padding: 0.625rem 0.75rem;
  }
  .gr-panel {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, 0.03);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    padding: 0.5rem;
  }
  .dark .gr-panel {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }
  .dark .gr-box-sm,
  .dark .gr-box-sm-gray-100,
  .dark .gr-box-sm-gray-200,
  .dark .gr-box-sm-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .gr-box-sm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .gr-box-sm {
    border-width: 1px;
    padding: 0.375rem 0.75rem;
  }
  .dark .gr-compact,
  .dark .gr-compact-gray-100,
  .dark .gr-compact-gray-200,
  .dark .gr-compact-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .gr-compact {
    align-items: stretch;
    gap: 0px;
    overflow: clip;
    border-radius: 0.5rem !important;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }
  .dark .gr-compact {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .gr-text-input {
    padding: 0.625rem;
    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .gr-text-input:disabled {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .dark .gr-check-radio {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .gr-check-radio {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .gr-check-radio:focus {
    --tw-border-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-border-opacity));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
    --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity));
    --tw-ring-opacity: 0.5;
    --tw-ring-offset-width: 0px;
  }
  .gr-check-radio:disabled {
    cursor: not-allowed !important;
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
  .dark .gr-check-radio {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }
  .dark .gr-check-radio:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity));
  }
  .dark .gr-check-radio:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity));
  }
  .gr-checkbox {
    border-radius: 0.25rem;
  }
  .gr-input-label {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
    --tw-gradient-from: #f9fafb;
    --tw-gradient-to: rgb(249 250 251 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-gradient-to: #fff;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, opacity, box-shadow, transform,
      filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .gr-input-label:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-to: rgb(243 244 246 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .dark .gr-input-label {
    --tw-gradient-from: #111827;
    --tw-gradient-to: rgb(17 24 39 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-gradient-to: #1f2937;
  }
  .gr-radio {
    border-radius: 9999px;
  }
  .dark .gr-button,
  .dark .gr-button-gray-100,
  .dark .gr-button-gray-200,
  .dark .gr-button-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .dark .gr-button {
    background-color: #0b0f19;
    --tw-bg-opacity: 1;
    background-color: rgb(11 15 25 / var(--tw-bg-opacity));
  }
  .gr-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    border-width: 1px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
    padding: 0.125rem 0.5rem;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .gr-button:hover {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
      0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .gr-button:active {
    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .dark .gr-button {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  }
  .gr-button-primary {
    --tw-border-opacity: 1;
    border-color: rgb(255 216 180 / var(--tw-border-opacity));
    --tw-gradient-from: rgb(255 216 180 / 0.7);
    --tw-gradient-to: rgb(255 216 180 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-gradient-to: rgb(255 176 102 / 0.8);
    --tw-text-opacity: 1;
    color: rgb(238 116 0 / var(--tw-text-opacity));
  }
  .gr-button-primary:hover {
    --tw-gradient-to: rgb(255 216 180 / 0.9);
  }
  .dark .gr-button-primary {
    --tw-border-opacity: 1;
    border-color: rgb(238 116 0 / var(--tw-border-opacity));
    --tw-gradient-from: #ce6400;
    --tw-gradient-to: rgb(206 100 0 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-gradient-to: #ce6400;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .dark .gr-button-primary:hover {
    --tw-gradient-to: #ff7c00;
  }
  .dark .gr-button-secondary {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
  }
  .dark .gr-button-secondary {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .gr-button-secondary {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
    --tw-gradient-from: rgb(243 244 246 / 0.7);
    --tw-gradient-to: rgb(243 244 246 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-gradient-to: rgb(229 231 235 / 0.8);
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
  }
  .gr-button-secondary:hover {
    --tw-gradient-to: rgb(243 244 246 / 0.9);
  }
  .dark .gr-button-secondary {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
    --tw-gradient-from: #4b5563;
    --tw-gradient-to: rgb(75 85 99 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-gradient-to: #374151;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .dark .gr-button-secondary:hover {
    --tw-gradient-to: #4b5563;
  }
  .gr-button-sm {
    border-radius: 0.375rem;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .gr-button-lg {
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
  }
  .gr-samples-table {
    width: 100%;
  }
  .gr-samples-table img.gr-sample-image,
  .gr-samples-table video.gr-sample-video {
    height: 5rem;
    width: 5rem;
    object-fit: cover;
  }
  .gr-samples-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .gr-samples-gallery img.gr-sample-image,
  .gr-samples-gallery video.gr-sample-video {
    max-height: 5rem;
    object-fit: cover;
  }
  .dark .gr-samples-gallery .gr-sample-textbox,
  .dark .gr-samples-gallery .gr-sample-textbox-gray-100,
  .dark .gr-samples-gallery .gr-sample-textbox-gray-200,
  .dark .gr-samples-gallery .gr-sample-textbox-gray-300,
  .dark .gr-samples-gallery .gr-sample-markdown,
  .dark .gr-samples-gallery .gr-sample-markdown-gray-100,
  .dark .gr-samples-gallery .gr-sample-markdown-gray-200,
  .dark .gr-samples-gallery .gr-sample-markdown-gray-300,
  .dark .gr-samples-gallery .gr-sample-html,
  .dark .gr-samples-gallery .gr-sample-html-gray-100,
  .dark .gr-samples-gallery .gr-sample-html-gray-200,
  .dark .gr-samples-gallery .gr-sample-html-gray-300,
  .dark .gr-samples-gallery .gr-sample-slider,
  .dark .gr-samples-gallery .gr-sample-slider-gray-100,
  .dark .gr-samples-gallery .gr-sample-slider-gray-200,
  .dark .gr-samples-gallery .gr-sample-slider-gray-300,
  .dark .gr-samples-gallery .gr-sample-checkbox,
  .dark .gr-samples-gallery .gr-sample-checkbox-gray-100,
  .dark .gr-samples-gallery .gr-sample-checkbox-gray-200,
  .dark .gr-samples-gallery .gr-sample-checkbox-gray-300,
  .dark .gr-samples-gallery .gr-sample-checkboxgroup,
  .dark .gr-samples-gallery .gr-sample-checkboxgroup-gray-100,
  .dark .gr-samples-gallery .gr-sample-checkboxgroup-gray-200,
  .dark .gr-samples-gallery .gr-sample-checkboxgroup-gray-300,
  .dark .gr-samples-gallery .gr-sample-file,
  .dark .gr-samples-gallery .gr-sample-file-gray-100,
  .dark .gr-samples-gallery .gr-sample-file-gray-200,
  .dark .gr-samples-gallery .gr-sample-file-gray-300,
  .dark .gr-samples-gallery .gr-sample-number,
  .dark .gr-samples-gallery .gr-sample-number-gray-100,
  .dark .gr-samples-gallery .gr-sample-number-gray-200,
  .dark .gr-samples-gallery .gr-sample-number-gray-300,
  .dark .gr-samples-gallery .gr-sample-audio,
  .dark .gr-samples-gallery .gr-sample-audio-gray-100,
  .dark .gr-samples-gallery .gr-sample-audio-gray-200,
  .dark .gr-samples-gallery .gr-sample-audio-gray-300,
  .dark .gr-samples-gallery .gr-sample-3d,
  .dark .gr-samples-gallery .gr-sample-3d-gray-100,
  .dark .gr-samples-gallery .gr-sample-3d-gray-200,
  .dark .gr-samples-gallery .gr-sample-3d-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .dark .gr-samples-gallery .gr-sample-textbox,
  .dark .gr-samples-gallery .gr-sample-markdown,
  .dark .gr-samples-gallery .gr-sample-html,
  .dark .gr-samples-gallery .gr-sample-slider,
  .dark .gr-samples-gallery .gr-sample-checkbox,
  .dark .gr-samples-gallery .gr-sample-checkboxgroup,
  .dark .gr-samples-gallery .gr-sample-file,
  .dark .gr-samples-gallery .gr-sample-number,
  .dark .gr-samples-gallery .gr-sample-audio,
  .dark .gr-samples-gallery .gr-sample-3d {
    background-color: #0b0f19;
    --tw-bg-opacity: 1;
    background-color: rgb(11 15 25 / var(--tw-bg-opacity));
  }
  .gr-samples-gallery .gr-sample-textbox,
  .gr-samples-gallery .gr-sample-markdown,
  .gr-samples-gallery .gr-sample-html,
  .gr-samples-gallery .gr-sample-slider,
  .gr-samples-gallery .gr-sample-checkbox,
  .gr-samples-gallery .gr-sample-checkboxgroup,
  .gr-samples-gallery .gr-sample-file,
  .gr-samples-gallery .gr-sample-number,
  .gr-samples-gallery .gr-sample-audio,
  .gr-samples-gallery .gr-sample-3d {
    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: 0.5rem;
    border-width: 1px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: 0.375rem 0.5rem;
    text-align: left;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .dark .gr-samples-gallery .gr-sample-textbox:hover,
  .dark .gr-samples-gallery .gr-sample-markdown:hover,
  .dark .gr-samples-gallery .gr-sample-html:hover,
  .dark .gr-samples-gallery .gr-sample-slider:hover,
  .dark .gr-samples-gallery .gr-sample-checkbox:hover,
  .dark .gr-samples-gallery .gr-sample-checkboxgroup:hover,
  .dark .gr-samples-gallery .gr-sample-file:hover,
  .dark .gr-samples-gallery .gr-sample-number:hover,
  .dark .gr-samples-gallery .gr-sample-audio:hover,
  .dark .gr-samples-gallery .gr-sample-3d:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }
  .gr-samples-gallery .gr-sample-textbox:hover,
  .gr-samples-gallery .gr-sample-markdown:hover,
  .gr-samples-gallery .gr-sample-html:hover,
  .gr-samples-gallery .gr-sample-slider:hover,
  .gr-samples-gallery .gr-sample-checkbox:hover,
  .gr-samples-gallery .gr-sample-checkboxgroup:hover,
  .gr-samples-gallery .gr-sample-file:hover,
  .gr-samples-gallery .gr-sample-number:hover,
  .gr-samples-gallery .gr-sample-audio:hover,
  .gr-samples-gallery .gr-sample-3d:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  }
  .dark .gr-samples-gallery .gr-sample-textbox,
  .dark .gr-samples-gallery .gr-sample-markdown,
  .dark .gr-samples-gallery .gr-sample-html,
  .dark .gr-samples-gallery .gr-sample-slider,
  .dark .gr-samples-gallery .gr-sample-checkbox,
  .dark .gr-samples-gallery .gr-sample-checkboxgroup,
  .dark .gr-samples-gallery .gr-sample-file,
  .dark .gr-samples-gallery .gr-sample-number,
  .dark .gr-samples-gallery .gr-sample-audio,
  .dark .gr-samples-gallery .gr-sample-3d {
    background-color: transparent;
  }
  .dark .gr-samples-gallery .gr-sample-textbox:hover,
  .dark .gr-samples-gallery .gr-sample-markdown:hover,
  .dark .gr-samples-gallery .gr-sample-html:hover,
  .dark .gr-samples-gallery .gr-sample-slider:hover,
  .dark .gr-samples-gallery .gr-sample-checkbox:hover,
  .dark .gr-samples-gallery .gr-sample-checkboxgroup:hover,
  .dark .gr-samples-gallery .gr-sample-file:hover,
  .dark .gr-samples-gallery .gr-sample-number:hover,
  .dark .gr-samples-gallery .gr-sample-audio:hover,
  .dark .gr-samples-gallery .gr-sample-3d:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }
  .dark .gr-samples-gallery .gr-sample-dataframe,
  .dark .gr-samples-gallery .gr-sample-dataframe-gray-100,
  .dark .gr-samples-gallery .gr-sample-dataframe-gray-200,
  .dark .gr-samples-gallery .gr-sample-dataframe-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .dark .gr-samples-gallery .gr-sample-dataframe {
    background-color: #0b0f19;
    --tw-bg-opacity: 1;
    background-color: rgb(11 15 25 / var(--tw-bg-opacity));
  }
  .gr-samples-gallery .gr-sample-dataframe {
    border-collapse: collapse;
    cursor: pointer;
    align-items: center;
    overflow: hidden;
    border-radius: 0.5rem;
    border-width: 1px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: 0.375rem 0.5rem;
    text-align: left;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .dark .gr-samples-gallery .gr-sample-dataframe:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }
  .gr-samples-gallery .gr-sample-dataframe:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  }
  .dark .gr-samples-gallery .gr-sample-dataframe {
    background-color: transparent;
  }
  .dark .gr-samples-gallery .gr-sample-dataframe:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }
  img.gr-sample-image,
  video.gr-sample-video {
    max-width: none;
    flex: none;
    border-radius: 0.5rem;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }
  img.gr-sample-image:hover,
  video.gr-sample-video:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 150 51 / var(--tw-border-opacity));
  }
  .group:hover img.gr-sample-image,
  .group:hover video.gr-sample-video {
    --tw-border-opacity: 1;
    border-color: rgb(255 150 51 / var(--tw-border-opacity));
  }
  .dark img.gr-sample-image,
  .dark video.gr-sample-video {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .dark img.gr-sample-image:hover,
  .dark video.gr-sample-video:hover {
    --tw-border-opacity: 1;
    border-color: rgb(206 100 0 / var(--tw-border-opacity));
  }
  .dark .group:hover img.gr-sample-image,
  .dark .group:hover video.gr-sample-video {
    --tw-border-opacity: 1;
    border-color: rgb(206 100 0 / var(--tw-border-opacity));
  }
  .block-holder {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.03);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }

  .block-holder-text {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.03);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.49);
  }

  .range input {
    -webkit-appearance: none;
    height: 4px;
    border-radius: 5px;
    background-color: color-bg(primary);
    outline: none;
  }

  .range input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: color-bg(primary);
    border: #fff 2px solid;
    // border-width: 1px;
  }

  .range span {
    position: absolute;
    margin-left: 14px;
    width: 50px;
    height: 30px;
    background-color: #222;
    color: #000;
    border-radius: 3px;
    text-align: center;
    line-height: 30px;
  }
  .range span:before {
    content: "";
    position: absolute;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    border-bottom: 12px solid #222;
    left: -10px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
  }
}
