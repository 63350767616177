.hero {
  .section-inner {
    padding-top: $hero--padding-t__mobile;
    padding-bottom: $hero--padding-b__mobile;
  }
}

.hero-inner {
  // Affects only hero full
  > .hero-content + .hero-figure,
  > .hero-figure + .hero-content {
    margin-top: $hero--inner-padding-v_mobile;
  }

  > .hero-figure {
    > a {
      display: inline-flex;
      // prevents stretching
      align-items: center;
      vertical-align: top;
    }
  }
}

.hero-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.hero-left {
  width: 80%;
}

.hero-right {
  display: flex;
  align-items: center;
  justify-content: center;
}
@include media("<=medium") {
  .hero {
    .split-wrap {
      .split-item {
        .split-item-content {
          margin-bottom: $hero--inner-padding-v_mobile;
        }
      }

      &.invert-mobile {
        .split-item {
          .split-item-image {
            margin-bottom: $hero--inner-padding-v_mobile;
          }
        }
      }
    }
  }

  .hero-inner {
    margin-left: 0;
    margin-right: 0;
  }

  .hero-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@include media(">medium") {
  .hero {
    .section-inner {
      padding-top: $hero--padding-t__desktop;
      padding-bottom: $hero--padding-b__desktop;
    }
  }

  .hero-inner {
    // Affects only hero full
    > .hero-content + .hero-figure,
    > .hero-figure + .hero-content {
      margin-top: $hero--inner-padding-v_desktop;
    }
  }
}
