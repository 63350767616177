.feedback-card-background {
  width: 610px;

  background: #ffffff;
  box-shadow: 10px 10px 20px rgba(8, 4, 33, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  padding: 5%;
}

.feedback-card-image {
  position: absolute;
  margin-top: -70px;
  margin-left: -50px;
}

.feedback-card-body {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #30295b;
}

.feedback-card-name {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;

  color: #080421;
}

.feedback-card-position {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #090909;
}

.slides {
  display: grid;
  > .slide {
    grid-area: 1 / -1;
  }

  .nav-button {
    appearance: none;
    background: transparent;
    border: none;
    color: color-bg(primary);
    font-size: 2.5rem;
    width: 2.5rem;
    height: 5rem;
    transition: opacity 0.3s;
    opacity: 0.7;
    z-index: 5;

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }

    &:first-child {
    }
    &:last-child {
    }
  }
}

.slide {
  //transform-style: preserve-3d;
  // border: solid 1px red;

  // &[data-active] {
  //   .slideContent > * {
  //     transform: none;
  //     opacity: 1;
  //   }
  // }
}

.slideContent {
  width: 530px;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
  height: 278px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform 0.5s ease-in-out;
  opacity: 0.7;
  padding: 5%;
  display: grid;
  align-content: center;

  transform-style: preserve-3d;
  transform: perspective(2500px) translateX(calc(100% * var(--offset)))
    rotateY(calc(-45deg * var(--dir)));
}

.slideContentInner {
  transform-style: preserve-3d;
  transform: translateZ(2rem);
  transition: opacity 0.3s linear;
  opacity: 0.2;

  .slideSubtitle,
  .slideTitle {
    font-size: 2rem;
    font-weight: normal;
    letter-spacing: 0.2ch;
    text-transform: uppercase;
    margin: 0;
  }

  .slideSubtitle::before {
    content: "— ";
  }

  .slideDescription {
    margin: 0;
    font-size: 0.8rem;
    letter-spacing: 0.2ch;
  }
}

.slide[data-active] {
  z-index: 2;
  pointer-events: auto;

  .slideBackground {
    opacity: 0.2;
    transform: none;
  }

  .slideContentInner {
    opacity: 1;
    padding: 5%;
  }

  .slideContent {
    --x: calc(var(--px) - 0.5);
    --y: calc(var(--py) - 0.5);
    opacity: 1;

    transform: perspective(1500px);

    &:hover {
      transition: none;
      transform: perspective(1000px) rotateY(calc(var(--x) * 45deg))
        rotateX(calc(var(--y) * -45deg));
    }
  }
}

.carousel-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 0;
  font-size: 3vmin;
}

.buttons-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
