.service-card {
  transition: box-shadow 0.6s;
  // background-color: #ffffff0d;
  border: #ffffff30 solid;
  border-width: 1px;
  border-radius: 20px;
  width: 280px;
  min-height: 280px;
  flex: 1 0 21%;
  text-align: center;
  padding: 25px;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 10px;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 5px 20px rgb(0 0 0 / 12%);

  @media screen and (max-width: 700px) {
    min-width: 40%;
  }
}

.service-image-background {
  display: flex;
}

.shadow {
  box-shadow: 10px 10px 20px rgba(8, 4, 33, 0.1);
}

.video-holder {
  // border: rgba(255, 255, 255, 0.5019607843) solid 0.5px;
  box-shadow: 0px 5px 20px rgb(0 0 0 / 12%);
  border-radius: 5px;
}

.services-holder {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3%;
  column-count: 3;
  justify-content: center;
}

.services-holder > * {
  flex: 0 0 30%;

  @media screen and (max-width: 700px) {
    flex: 100%;
  }
}
.service-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px;
  color: color-bg(white);
}

.service-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: left;

  color: color-bg(white);
}

.service-image {
  padding: 10px;
}

.hero-secondary-ticks {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3%;
}

.hero-secondary-ticks-project {
  display: flex;
  flex-wrap: wrap;
}

.hero-secondary-ticks-holder {
  display: flex;
  align-items: center;
  background: #ffffff;
  flex: 1 0 21%;
  text-align: center;
  padding: 25px;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 10px;
  justify-content: center;
}

.helpdesk-details-text {
  flex: 50%;
  @media screen and (max-width: 700px) {
    flex: 100%;
  }
  padding-left: 10px;
  text-align: left;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */
  padding: 5px;

  color: #090909;
}

.secondary-tick-holder {
  flex: 50% 1;
  @media screen and (max-width: 700px) {
    flex: 100%;
  }
  display: flex;
  align-items: flex-start;
  padding-left: 5%;
}
