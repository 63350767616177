.input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #fff;
  padding: 0px 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-bottom: 10px;
  }
}

.file-selector-button {
  display: flex;
  flex-direction: row;
  width: fit-content;
  max-width: 100%;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: var(--hff);
  padding-left: 5rem;
  padding-right: 5rem;
  position: relative;
  color: var(--hc);
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 10px;
  outline: none;
  outline-color: transparent;
  cursor: pointer;
}

.input-button {
  padding: auto;
  display: flex;
  cursor: pointer;

  p {
    align-self: center;
    margin-bottom: 0;
  }
}

.input-button:hover {
  background-color: color-bg(primary);

  p {
    color: #000;
  }
}

.rich-text {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #d2d2d2;
  padding: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #30295b;
  background-color: #000;
  resize: none;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-bottom: 10px;
  }
}

.input:active {
  border: 1px solid #d2d2d2;
}

.app-product-screen {
  width: 100%;
}

.form-holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.image-add-input {
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}

.form-paragraph {
  width: 100%;
}

.images-holder {
  width: 100%;
  height: 500px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #30295b;
  background-color: #000;

  resize: none;
}

.images-add-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #30295b;
  background-color: #000;
  border-radius: 10px;
  border: 1px solid #d2d2d2;
  cursor: pointer;
}

.images-add-holder:hover {
  filter: brightness(0.98);
}

#react-autowhatever-1 {
  border-radius: 10px;
}

#react-autowhatever-1::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
#react-autowhatever-1::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 10px;
}
#react-autowhatever-1::-webkit-scrollbar-thumb {
  background-color: #6163ff;
  border-radius: 10px;
}

.product-price {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.input-price {
  float: right;
  input {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.categories-selector-holder {
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  justify-content: center;

  overflow-y: visible;

  .category-selector-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    overflow-y: scroll;
  }

  label {
    padding: 10px;
    width: 100%;

    cursor: pointer;
  }
  label:hover {
    padding: 10px;
    width: 100%;
    background-color: #efeeee;
    border-radius: 10px;
  }
}

.category-record {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.category-selector-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.category-selector-list::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 10px;
}
.category-selector-list::-webkit-scrollbar-thumb {
  background-color: #6163ff;
  border-radius: 10px;
}

.right-button {
  float: right;
  cursor: pointer;
  &:hover {
    color: color-bg(primary);
  }
}
.carousel-image {
  object-fit: cover !important;
}

.thumbnail {
  height: 150px;
  width: 200px;
  object-fit: cover;
}

.image-buttons-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 180px;

  div {
    margin: 0px 10px;
    width: 150px;
    div {
      flex: 2;
    }

    p {
      flex: 5;
      text-align: left;
      margin-right: 10px;
    }
  }
}

.product-address {
  width: 100%;
  border-radius: 10px;

  .input {
    margin-bottom: 20px;
  }

  div {
    border-radius: 10px;
  }
}

.map-holder {
  height: 700px;
}

.entrance-holder {
  display: flex;
  flex-direction: row;
  column-gap: 10px;

  input {
    flex: 1;
  }
}

.add-product-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.error {
  border: 1.4px solid #ff0000;
}

.code-search-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.code-search {
  width: 30% !important;
}

.address-search-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.color-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
  border-radius: 10px 0px 0px 10px;
}

.switch {
  --line: #e8ebfb;
  --dot: color-bg(primary);
  --circle: #d3d4ec;
  --background: #fff;
  --duration: 0.3s;
  --text: #9ea0be;
  --shadow: 0 1px 3px #{rgba(#00093d, 0.08)};
  cursor: pointer;
  position: relative;
  &:before {
    content: "";
    width: 60px;
    height: 32px;
    border-radius: 16px;
    background: var(--background);
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: var(--shadow);
  }
  input {
    display: none;
    & + div {
      position: relative;
      &:before,
      &:after {
        --s: 1;
        content: "";
        position: absolute;
        height: 4px;
        top: 14px;
        width: 24px;
        background: var(--line);
        transform: scaleX(var(--s));
        transition: transform var(--duration) ease;
      }
      &:before {
        --s: 0;
        left: 4px;
        transform-origin: 0 50%;
        border-radius: 2px 0 0 2px;
      }
      &:after {
        left: 32px;
        transform-origin: 100% 50%;
        border-radius: 0 2px 2px 0;
      }
      span {
        padding-left: 60px;
        line-height: 28px;
        color: var(--text);
        &:before {
          --x: 0;
          --b: var(--circle);
          --s: 4px;
          content: "";
          position: absolute;
          left: 4px;
          top: 4px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          box-shadow: inset 0 0 0 var(--s) var(--b);
          transform: translateX(var(--x));
          transition: box-shadow var(--duration) ease,
            transform var(--duration) ease;
        }
        &:not(:empty) {
          padding-left: 68px;
        }
      }
    }
    &:checked {
      & + div {
        &:before {
          --s: 1;
        }
        &:after {
          --s: 0;
        }
        span {
          &:before {
            --x: 28px;
            --s: 12px;
            --b: var(--dot);
          }
        }
      }
    }
  }
}

.product-toggle-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  p {
    margin-bottom: 0 !important;
  }
}

.input-condition {
  width: 18%;
}

.input-size {
  width: 50%;
}

.product-dimensions-holder {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 10px;

  input {
    width: 100%;
  }
}

.product-colors-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;

  input {
    width: 100%;
  }
}

.dimension {
  width: 100%;
}
