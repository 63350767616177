.add-short-container {
  width: 80%;
  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    width: 500px;
    min-height: 550px;
    padding: 20px;
    row-gap: 15px;
    padding-top: 70px;

    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }

  .modal-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 100;
  }
}

.sign-in-container {
  @media screen and (max-width: 700px) {
    position: relative !important;
  }
  h1 {
    font-weight: bold;
    margin: 0;
  }

  h2 {
    text-align: center;
  }

  p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
  }

  span {
    font-size: 12px;
  }

  a {
    text-decoration: none;
  }

  button {
    border-radius: 20px;
    border: 1px solid #735dfe;
    background-color: #735dfe;
    color: #000;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }

  button:active {
    transform: scale(0.95);
  }

  button:focus {
    outline: none;
  }

  button.ghost {
    background-color: transparent;
    border-color: #000;
  }

  form {
    background-color: color-bg(primary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    text-align: center;
    row-gap: 12px;
  }

  input:not([type="checkbox"]) {
    height: 40px;
    margin: 10px;
    width: 95%;
    border-radius: 5px;
    border: 1px solid #efeeee;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #30295b;
  }

  .modal-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 100;
  }

  .name-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 80%;

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    input {
      width: 100% !important;
      margin-left: 0px;
    }
  }

  .container {
    background-color: color-bg(primary);
    border-radius: 5px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    width: 500px;
    min-height: 650px;

    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }

  .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
    padding: 30px;
  }

  .sign-in-container {
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: color-bg(primary);
  }

  .add-short-container {
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 10px;
  }

  .container.right-panel-active .sign-in-container {
    transform: translateX(100%);
  }

  .sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
  }

  .container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  }

  @keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }

  .container.right-panel-active .overlay-container {
    transform: translateX(-100%);
  }

  .overlay {
    background: #735dfe;
    background: -webkit-linear-gradient(to right, #735dfe, #8e7df9);
    background: linear-gradient(to right, #735dfe, #8e7df9);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #000;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .container.right-panel-active .overlay {
    transform: translateX(50%);
  }

  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .overlay-left {
    transform: translateX(-20%);
  }

  .container.right-panel-active .overlay-left {
    transform: translateX(0);
  }

  .overlay-right {
    right: 0;
    transform: translateX(0);
  }

  .container.right-panel-active .overlay-right {
    transform: translateX(20%);
  }

  .social-container {
    margin: 20px 0;
  }

  .social-container a {
    border: 1px solid #dddddd;
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
  }

  footer {
    background-color: #222;
    color: #000;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
  }

  footer p {
    margin: 10px 0;
  }

  footer i {
    color: red;
  }

  footer a {
    color: #3c97bf;
    text-decoration: none;
  }
}
