.site-footer {
  position: relative;
  width: 90%;
  border-radius: "20px";
  margin: auto;
  margin-top: 100px;
  margin-bottom: 20px;
  padding: 5%;
  border-radius: $button--radius;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -3;
  }
}

.footer-title {
  color: color-bg(white);
}

.site-footer-inner {
  padding: $footer--padding__mobile 0;
}

.footer-top {
  padding-bottom: $footer-inner--padding-v * 0.5;
}

.footer-bottom {
  padding-top: $footer-inner--padding-v * 0.5;

  @media screen and (max-width: 700px) {
    justify-content: center !important;
  }
}

.footer-social-holder {
  @media screen and (max-width: 700px) {
    justify-content: center !important;
  }
}

.footer-copyright {
  text-align: left;
  margin-bottom: 10px !important;
  color: color-bg(primary);
}

.footer-circle {
  position: absolute;
  right: -5%;
  z-index: -1;
}

.divider {
  width: 95%;
  height: 0.5px;
  background-color: color-bg(white);
  opacity: 50%;
  z-index: 100;
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer-blocks {
  display: flex;
  flex-wrap: wrap;
  margin-right: -($footer-block--padding_h * 0.5);
  margin-left: -($footer-block--padding_h * 0.5);
  margin-top: -($footer-block--padding_v * 0.5);

  &:last-of-type {
    margin-bottom: -($footer-block--padding_v * 0.5) + $footer-blocks--padding;
  }

  &:not(:last-of-type) {
    margin-bottom: ($footer-block--padding_v * 0.5);
  }
}

.footer-block {
  flex-grow: 1;
  flex-basis: $footer-block--min-width;
  box-sizing: content-box;
  padding: $footer-block--padding_v * 0.5 $footer-block--padding_h * 0.5;
  * {
    box-sizing: border-box;
  }

  ul li {
    &:not(:last-child) {
      margin-bottom: $footer-block-list-padding;
    }
  }

  a {
    @include anchor-aspect(footer-block);
    @include font-weight(footer-block-link);
    text-transform: $link-footer-block--transform;

    .invert-color & {
      @include anchor-aspect(footer-block, inverse);
    }
  }
}

.footer-block-title {
  @include font-size(block-title);
  @include font-weight(block-title);
  color: color(block-title);
  text-transform: $footer-block-title--transform;
  margin-bottom: $footer-block-title-padding;

  .invert-color & {
    color: color(block-title-inverse);
  }
}

.footer-social,
.footer-nav {
  @media screen and (max-width: 700px) {
    width: 100% !important;

    ul {
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;

      row-gap: 20px;

      li {
        width: 100% !important;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center !important;
        }
      }
    }
  }

  a {
    display: flex;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    white-space: nowrap;
    margin-bottom: 0;
  }
}

.footer-nav-title {
  display: flex;
  flex-direction: column;
  column-gap: 100px;
  @media screen and (max-width: 700px) {
    column-gap: 20px !important;
  }
}

.footer-social {
  ul {
    margin-right: -(
        $footer-social--inner-padding + $footer-social--outer-padding-h
      ) - 5;
    margin-left: -(
        $footer-social--inner-padding + $footer-social--outer-padding-h
      ) - 5;
  }

  li {
    padding: 0 0;
  }

  a {
    padding: $footer-social--inner-padding;

    svg {
      fill: color-icon(social);
      transition: fill 0.15s ease;

      .invert-color & {
        fill: color-icon(social-inverse);
      }
    }

    &:hover {
      svg {
        fill: color-icon(social-hover);

        .invert-color & {
          fill: color-icon(social-hover-inverse);
        }
      }
    }
  }
}

.footer-nav {
  margin-right: -$footer-nav--padding-h;
  margin-left: -$footer-nav--padding-h;

  a {
    @include anchor-aspect(footer);
    @include font-weight(footer-link);
    text-transform: $link-footer--transform;

    color: color-bg(white);
    .invert-color & {
      @include anchor-aspect(footer, inverse);
    }
  }
}

.footer-copyright {
  color: color-bg(white);
}

@include media("<=medium") {
  .footer-top,
  .footer-bottom {
    > *:not(:last-child) {
      margin-bottom: $footer-inner--padding-v;
    }
  }
}

@include media(">medium") {
  @if ($footer--padding__mobile != $footer--padding__desktop) {
    .site-footer-inner {
      padding: $footer--padding__desktop 0;
      padding-bottom: 10px;
    }
  }

  .footer-top,
  .footer-bottom {
    justify-content: center;
    &.space-between {
      display: flex;
      justify-content: center;
    }

    &.space-between-get-in-touch {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  .footer-top,
  .footer-bottom {
    &.invert-order-desktop {
      > *:first-child {
        order: 1;
      }
    }
  }
}

.footer-align {
  display: flex;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}
