.product-thumbnail {
  .wrapper {
    width: 100%;
    height: 450px;
    background: white;
    margin: auto;
    position: relative;
    overflow: hidden;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0;
    transform: scale(0.98);
    transition: box-shadow 0.5s, transform 0.5s;
    border: solid thin rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 700px) {
      width: 100%;
    }

    &.small {
      height: 300px !important;
      width: 320px !important;
    }

    &:hover {
      transform: scale(1);
      box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
    }

    .product-title {
      text-overflow: ellipsis;
    }

    .overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: rgba($color: color-bg(primary), $alpha: 0.5);
    }

    .container {
      width: 100%;
      height: 100%;

      .top {
        height: 80%;
        width: 100%;
        background: no-repeat center center;
        -webkit-background-size: 100%;
        -moz-background-size: 100%;
        -o-background-size: 100%;
        background-size: 100%;

        &.small {
          height: 200px !important;
        }

        &.selected {
          filter: brightness(0.2);
        }
      }
      .bottom {
        width: 200%;
        height: 20%;
        transition: transform 0.5s;

        &.small {
          height: 200px !important;
        }

        &.clicked {
          transform: translateX(-50%);
        }
        h1 {
          margin: 0;
          padding: 0;
        }
        p {
          margin: 0;
          padding: 0;
        }
        .left {
          padding-top: 10px;
          height: 100%;
          width: 50%;
          background: #fff;
          position: relative;
          float: left;
          // border: solid thin rgba(0, 0, 0, 0.1);
          border-radius: 0px 0px 10px 10px;

          &.small {
            height: 200px !important;
          }
          .details {
            padding: 20px;
            float: left;
          }
          .buy {
            position: absolute;
            width: 3em;
            height: 3em;
            border-radius: 1.5em;
            display: flex;
            justify-content: center;
            align-items: center;
            float: right;
            background: #fff;
            margin-top: -40px;
            right: 10px;
            transition: background 0.5s;
            box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
            color: color-bg(primary);

            &:hover {
              background: #6163ff;
            }
            &:hover i {
              color: #000;
              svg {
                color: #000;
              }
            }
          }
        }
        .right {
          width: 50%;
          background: #6163ff;
          color: white;
          float: right;
          height: 200%;
          overflow: hidden;
          .details {
            padding: 20px;
            float: right;
            width: calc(70% - 40px);
          }
          .done {
            width: calc(30% - 2px);
            float: left;
            transition: transform 0.5s;
            border-right: solid thin rgba(255, 255, 255, 0.3);
            height: 50%;
            i {
              font-size: 30px;
              padding: 30px;
              color: white;
            }
          }
          .remove {
            width: calc(30% - 1px);
            clear: both;
            border-right: solid thin rgba(255, 255, 255, 0.3);
            height: 50%;
            background: #bc3b59;
            transition: transform 0.5s, background 0.5s;
            &:hover {
              background: #9b2847;
            }
            &:hover i {
              transform: translateY(5px);
            }
            i {
              transition: transform 0.5s;
              font-size: 30px;
              padding: 30px;
              color: white;
            }
          }
          &:hover {
            .remove,
            .done {
              transform: translateY(-100%);
            }
          }
        }
      }
    }

    .inside {
      z-index: 9;
      background: #6163ff;
      width: 140px;
      height: 140px;
      position: absolute;
      top: -70px;
      right: -70px;
      border-radius: 0px 0px 200px 200px;
      transition: all 0.1s, border-radius 1s, top 0.1s;
      overflow: hidden;
      .icon {
        position: absolute;
        right: 85px;
        top: 85px;
        color: white;
        opacity: 1;
      }
      &:hover {
        width: 100%;
        right: 0;
        top: 0;
        border-radius: 0;
        height: 80%;
        .icon {
          opacity: 0;
          right: 15px;
          top: 15px;
        }
        .contents {
          opacity: 1;
          transform: scale(1);
          transform: translateY(0);
        }
      }
      .contents {
        padding: 5%;
        opacity: 0;
        transform: scale(0.5);
        transform: translateY(-200%);
        transition: opacity 0.2s, transform 0.8s;
        table {
          text-align: left;
          width: 100%;
        }
        h1,
        p,
        table {
          color: white;
        }
        p {
          font-size: 13px;
        }
      }
    }
  }

  cursor: pointer;
}

.name-surname-holder {
  display: flex;
  flex-direction: row;
  column-gap: 20px;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    row-gap: 20px;
  }
}

.signup-form-holder {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 700px) {
    width: 90%;
    row-gap: 10px;
  }
}
.product-thumbnails-holder {
  position: relative;
  display: grid;
  margin-bottom: 5%;

  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-auto-rows: 450px;
  grid-gap: 5px;
}
