.comment-holder {
  body {
    margin-top: 20px;
  }

  .content-item {
    padding: 30px 0;
    background-color: #000;
  }

  .content-item.grey {
    background-color: #f0f0f0;
    padding: 50px 0;
    height: 100%;
  }

  .content-item h2 {
    font-weight: 700;
    font-size: 35px;
    line-height: 45px;
    text-transform: uppercase;
    margin: 20px 0;
  }

  .content-item h3 {
    font-weight: 400;
    font-size: 20px;
    color: #555555;
    margin: 10px 0 15px;
    padding: 0;
  }

  .content-headline {
    height: 1px;
    text-align: center;
    margin: 20px 0 70px;
  }

  .content-headline h2 {
    background-color: #000;
    display: inline-block;
    margin: -20px auto 0;
    padding: 0 20px;
  }

  .grey .content-headline h2 {
    background-color: #f0f0f0;
  }

  .content-headline h3 {
    font-size: 14px;
    color: #aaaaaa;
    display: block;
  }

  #comments {
    box-shadow: 0 -1px 6px 1px rgba(0, 0, 0, 0.1);
    background-color: #000;
  }

  #comments form {
    margin-bottom: 30px;
  }

  #comments .btn {
    margin-top: 7px;
  }

  #comments form fieldset {
    clear: both;
  }

  #comments form textarea {
    height: 100px;
  }

  .media {
    display: flex;
    flex-direction: row;
    border-top: 1px dashed #dddddd;
    padding: 20px 0;
    margin: 0;
  }

  .media-heading {
    color: "#000";
  }
  #comments .media > .pull-left {
    margin-right: 20px;
  }

  #comments .media h4 {
    margin: 0 0 10px;
  }

  #comments .media h4 span {
    font-size: 14px;
    float: right;
    color: #999999;
  }

  #comments .media p {
    margin-bottom: 15px;
    text-align: justify;
  }

  #comments .media-detail {
    margin: 0;
  }

  #comments .media-detail li {
    color: #aaaaaa;
    font-size: 12px;
    padding-right: 10px;
    font-weight: 600;
  }

  #comments .media-detail a:hover {
    text-decoration: underline;
  }

  #comments .media-detail li:last-child {
    padding-right: 0;
  }

  #comments .media-detail li i {
    color: #666666;
    font-size: 15px;
    margin-right: 10px;
  }

  .media-object {
    max-width: none !important;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .media-body {
    padding: 10px;
  }

  .date {
    float: right;
    color: #ababab;
  }

  .user-icon {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    object-fit: cover;
  }

  .comment-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 20px;
  }

  .send-button {
    float: right;
    padding: 20px;
  }

  .rich-text {
    width: 90%;

    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }

  .comment-input-holder {
    width: 100%;
  }
}
