.product-container {
  $white: #fff;
  $black: #333;
  $lightergray: #d5d5d5;
  $lightgray: #f0f3f1;
  $gray: #e3dddd;
  $text: #888;
  $button: #3e3e3f;
  $button-hover: #565657;

  font-size: 1.125rem;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  color: $text;
  text-rendering: optimizeLegibility;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin: 0 1.75rem 0 0;
    }
  }

  a {
    color: $text;
    text-decoration: none;
    transition: all 0.2s ease;
    &:hover {
      color: $black;
    }
    &.active {
      color: $black;
    }
  }

  .button-primary-2 {
    padding: 15px !important;
  }

  .typography-h1,
  .typography-h2,
  .typography-h3,
  .typography-h4 {
    color: $black !important;
    font-weight: normal;
    margin: 1.75rem 0 1rem 0;
  }

  .typography-h1 {
    font-size: 2.5rem;
  }

  .typography-h2 {
    font-size: 2.125rem;
    margin: 0;
  }

  .typography-h3 {
    font-size: 2rem;
  }

  .typography-h4 {
    font-size: 1.5rem;
  }

  section {
    display: block;
  }

  // img {
  //   max-width: 100%;
  //   height: auto;
  //   object-fit: cover;
  // }

  nav {
    display: block;
    li {
      font-size: 1.125rem;
      margin: 0;
    }
  }

  .flex-nav ul {
    position: absolute;
    z-index: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    display: none;
    width: 100%;
    left: 0;
    padding: 1rem;
    background: $white;
    text-align: center;
    &.active {
      display: flex;
    }
    li {
      margin: 0.5rem 0;
    }
  }

  .toggle-nav {
    display: flex;
    justify-content: flex-end;
    font-size: 1.125rem;
    line-height: 1.7;
    margin: 1rem 0;
    i {
      font-size: 1.5rem;
      line-height: 1.4;
      margin: 0 0 0 0.5rem;
    }
  }

  #highlight {
    color: $black;
    font-size: 1.125rem;
    text-transform: uppercase;
  }

  .price {
    margin: 0;
  }

  .breadcrumb-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 1rem 0 0 0;
    list-style: none;
    li {
      font-size: 0.85rem;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
    }
  }

  .breadcrumb-item {
    &.active {
      color: $black;
    }
    + .breadcrumb-item {
      &::before {
        content: "/";
        display: inline-block;
        padding: 0 0.5rem;
        color: $lightergray;
      }
    }
  }

  .description {
    border-top: 0.0625rem solid $gray;
    margin: 2rem 0;
    padding: 1rem 0 0 0;
  }

  .add-to-cart {
    position: relative;
    display: inline-block;
    background: $button;
    color: $white;
    border: none;
    border-radius: 0;
    padding: 1.25rem 2.5rem;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    transform: translateZ(0);
    transition: color 0.3s ease;
    letter-spacing: 0.0625rem;
    &:hover {
      &::before {
        transform: scaleX(1);
      }
    }
    &::before {
      position: absolute;
      content: "";
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $button-hover;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition: transform 0.3s ease-out;
    }
  }

  .product-holder {
    margin: auto;
    padding: 0 1rem;
    max-width: 75rem;
    width: 100%;
  }

  .grid {
    > [class*="column-"] {
      padding: 1rem;
    }
    &.menu,
    &.product {
      border-bottom: 0.0625rem solid $gray;
      display: flex;
      flex-direction: row;
      padding-bottom: 120px !important;

      @media screen and (max-width: 700px) {
        flex-direction: column;
      }
    }
    &.menu {
      > [class*="column-"] {
        padding: 0.5rem 1rem 0.5rem 1rem;
      }
    }
    &.product {
      padding: 0 0 1.5rem 0;
    }
    &.second-nav {
      > [class*="column-"] {
        padding: 0.5rem 1rem;
      }
    }
  }

  footer {
    padding: 1rem 0;
    text-align: center;
  }

  .product-image {
    display: none;
  }

  .image-list {
    li {
      margin: 0;
    }
  }

  @media (min-width: 700px) {
    .product-image img,
    .image-list img {
      width: 100%;
    }

    .product-image {
      display: block;
      img {
        height: 52vh;
        &.active {
          display: block;
          margin: 0 0 0.75rem 0;
        }
      }
    }

    .image-list {
      display: flex;
      overflow: hidden;
      li {
        margin: 0 0.75rem 0 0;
        flex-basis: 100%;
        &:nth-child(3) {
          margin: 0;
        }
      }
      img {
        height: 10rem;
        width: 100%;
        transition: opacity 0.3s ease;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }

    nav ul {
      justify-content: flex-end;
    }

    .toggle-nav {
      display: none;
    }

    .flex-nav {
      display: block;
      ul {
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: flex-end;
        li {
          font-size: 1.125rem;
          margin: 0 1.5rem 0 0;
          &:nth-child(4) {
            margin: 0;
          }
        }
      }
    }
  }

  @keyframes fadeImg {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .recommendation-holder {
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    padding: 10px;

    @media screen and (max-width: 700px) {
      padding: 40px;
    }
  }

  .recommendation-holder::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .recommendation-holder::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }
  .recommendation-holder::-webkit-scrollbar-thumb {
    background-image: linear-gradient(45deg, #00aeff, #a68eff);
    border-radius: 10px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0 3px 13px 1px;
  }
}

.action-buttons-holder {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.link {
  margin-top: 10px;
  cursor: pointer;
}

.link-by {
  color: color-bg(primary);
}

.fade {
  opacity: 1;
  animation-name: none;
}

.fade:not(.show) {
  opacity: 1;
}

.additional-details-section {
  margin-top: 30px;
}

.subtitle {
  margin-bottom: 30px !important;
  margin-top: 100px !important;
}

.product-map-holder {
  margin-top: 300px;
  height: 300px;
  border-radius: 10px;

  div {
    border-radius: 10px;
  }
}

.product-count-input {
  width: 50px;
  margin-right: 5px;
}

.product-count-input::-webkit-outer-spin-button,
.product-count-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.product-count-input[type="number"] {
  -moz-appearance: textfield;
}

.like-button {
  position: absolute;
  width: 3em;
  height: 3em;
  border-radius: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  background: #fff;
  margin-top: -50px;
  margin-right: 20px;
  transition: background 0.5s;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  color: color-bg(primary);

  &:hover {
    background: #6163ff;
  }
  &:hover i {
    color: #000;
    svg {
      color: #000;
    }
  }
}

.like-button-holder {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.interactions-holder {
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: flex-start;
  margin-top: 30px;
  float: left;
  margin-bottom: 30px;
}

.glyphs-holder {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
  p {
    margin-bottom: 0;
  }
}

.likes-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  color: color-bg(primary);
}

.rating-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: color-bg(primary);
  column-gap: 5px;
}

.views-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: color-bg(primary);
  column-gap: 5px;
}

.discount-price {
  text-decoration: line-through;
  color: #aaaaaa;
  opacity: 0.5;
}

.menu {
  position: absolute;
  right: 10px;
  float: right;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  box-sizing: border-box;
  .opener {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    position: relative;
    border-radius: 10px;
    transition: background-color 100ms ease-in-out;
    &:hover {
      background-color: #f2f2f2;
    }
    span {
      background-color: #404040;
      width: 0.4rem;
      height: 0.4rem;
      position: absolute;
      top: 0;
      left: calc(50% - 0.2rem);
      border-radius: 50%;
      &:nth-child(1) {
        top: 0.45rem;
      }
      &:nth-child(2) {
        top: 1.05rem;
      }
      &:nth-child(3) {
        top: 1.65rem;
      }
    }
  }
}

.no-comment {
  text-align: center;
  margin-top: 30px;
}

.edit-buttons-holder {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
