.container,
.container-sm,
.container-xs {
  width: 100%;
  margin: 0 auto;
  @include media(">small") {
  }
}

[class*="container"] {
  [class*="container"] {
  }
}
