.home-title {
  left: 133px;
  top: 251px;
  z-index: 2;
  width: 100%;

  font-style: normal;
  font-size: 50px;
  line-height: 60px;
  text-align: left;
  /* or 120% */

  display: flex;
  align-items: center;

  color: #000;
  margin-top: 100px;
}

.circle-orbit {
  margin-top: -100px;
  margin-right: -200px;
  position: absolute;
  right: 10%;
  z-index: 1;
}

.home-title-light {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  color: color-bg(white);
  text-align: center;
}

.home-title-dark {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-top: 2%;
  color: color-bg(white);
  text-align: center;
}

.home-body {
  left: 133px;
  top: 401px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  margin-top: 30px;
  margin-bottom: 60px;
  color: #30295b;
}

.hero-inner {
  /* Split hero in two half */
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 700px) {
    height: auto !important;
  }
}

.home-circle {
  position: absolute;
  right: -10%;
  z-index: 1;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background-color: color-bg(body-3);
  top: 5%;
}

.home-circle-black {
  position: relative;
  z-index: 0;
  height: 200px;
  width: 110%;
  top: -10%;
  border-radius: 50% 50% 0 0;
  background: color-bg(primary);
}

.home-illustration {
  position: absolute;
  z-index: 10;
  left: 0;
  width: 100%;
  top: 8%;
}

.home-circle-black-border {
  position: relative;
  z-index: 0;
  height: 200px;
  width: 105%;
  top: -18%;
  border-radius: 50% 50% 0 0;
  border-top: solid 2px #545454;
}

.hero-cta {
  z-index: 10;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-top: 20px;
  justify-content: center;
}

.store-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
}

.view-more-button-holder {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 5%;
}

.info-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media screen and (max-width: 700px) {
    margin-top: 0px !important;
  }
}

.hero-images-holder {
  @media screen and (max-width: 700px) {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: -20px;
    padding: 0;
  }
}

.info-box-1-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding: 10px;
  /* or 133% */

  text-align: center;

  color: #30295b;
}
